import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { RecorderState } from '../../store';
import { streams } from '../utils/streamUtils';

const AudioWaveform = () => {
  const [dataArray, setDataArray] = useState<Uint8Array>(new Uint8Array(0));
  const animationIdRef = useRef<number | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const { status } = useSelector((state: RecorderState) => state.recorder);
  const theme = useTheme();
  const waveformColor = theme.palette.secondary.main;

  useEffect(() => {
    const stream = streams.mergedStream;
    if (stream?.getAudioTracks()?.length > 0) {
      const audioContext = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);

      source.connect(analyser);

      analyser.fftSize = 256;

      const bufferLength = analyser.frequencyBinCount;
      const tempDataArray = new Uint8Array(bufferLength);

      analyserRef.current = analyser;

      const draw = () => {
        analyser.getByteFrequencyData(tempDataArray);
        setDataArray(new Uint8Array(tempDataArray));

        animationIdRef.current = requestAnimationFrame(draw);
      };

      draw();

      return () => {
        if (animationIdRef.current) {
          cancelAnimationFrame(animationIdRef.current);
        }
        analyser.disconnect();
        source.disconnect();
      };
    }
    return undefined;
  }, [status]);

  const BAR_WIDTH = 4;
  const BAR_GAP = 1; // Reduced gap to allow bars to extend further
  const SVG_HEIGHT = 120;
  const halfBufferLength = dataArray.length / 2;
  const SVG_WIDTH = halfBufferLength * (BAR_WIDTH + BAR_GAP) * 2;

  // Normalize dataArray to avoid saturation in the middle
  const maxVal = Math.max(...dataArray, 1); // Prevent division by zero
  const normalizedDataArray = dataArray.map((value) => (value / maxVal) * 255);

  const leftBars = Array.from(
    normalizedDataArray.slice(0, halfBufferLength)
  ).reverse();
  const rightBars = Array.from(normalizedDataArray.slice(0, halfBufferLength));

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
        preserveAspectRatio="none" // This ensures the SVG stretches to fill container
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {leftBars.map((value, index) => (
          <>
            <rect
              key={`left-top-${index}`}
              x={index * (BAR_WIDTH + BAR_GAP)}
              y={SVG_HEIGHT / 2 - (value / 255) * (SVG_HEIGHT / 2)}
              width={BAR_WIDTH}
              height={(value / 255) * (SVG_HEIGHT / 2)}
              fill={waveformColor}
            />
            <rect
              key={`left-bottom-${index}`}
              x={index * (BAR_WIDTH + BAR_GAP)}
              y={SVG_HEIGHT / 2}
              width={BAR_WIDTH}
              height={(value / 255) * (SVG_HEIGHT / 2)}
              fill={waveformColor}
            />
          </>
        ))}
        {rightBars.map((value, index) => (
          <>
            <rect
              key={`right-top-${index}`}
              x={SVG_WIDTH / 2 + index * (BAR_WIDTH + BAR_GAP)}
              y={SVG_HEIGHT / 2 - (value / 255) * (SVG_HEIGHT / 2)}
              width={BAR_WIDTH}
              height={(value / 255) * (SVG_HEIGHT / 2)}
              fill={waveformColor}
            />
            <rect
              key={`right-bottom-${index}`}
              x={SVG_WIDTH / 2 + index * (BAR_WIDTH + BAR_GAP)}
              y={SVG_HEIGHT / 2}
              width={BAR_WIDTH}
              height={(value / 255) * (SVG_HEIGHT / 2)}
              fill={waveformColor}
            />
          </>
        ))}
      </svg>
    </div>
  );
};

export default AudioWaveform;
