import api from '../lib/baseAxios';

export const initPlugin = async (key: string) => {
  return api.get(`plugin/init/${key}`);
};

export const getPlugin = () => {
  return api.get('plugin/setup');
};

export const setUpPlugin = (data: { website: string }) => {
  return api.post('plugin/setup', data);
};
