import {
  BaseResponse,
  FolderType,
  FileSystemEntityType,
  IntegrationsSettings,
} from '../types';
import {
  UserData,
  TeamSpace,
  RecorderSettings,
  UISettings,
} from '../types/state';
import { apiV2 } from '../lib/baseAxios';
import {
  NewSettings,
  SettingsKeys,
  UserSettings,
} from '../features/settings/settingsSlice';
import { IVFSResponse } from './fileSystemService';
import { IUsage, PackageProperties } from '../features/plan/userPackage';

export interface ISignUpData {
  name: string;
  email: string;
  password: string;
  redirectUrl?: string;
}

export interface UpdateUserProfileData {
  name: string;
  pictureMime?: string;
  picture?: string;
}

export interface ResetPasswordPayload {
  password: string;
  confirm: string;
  token: string;
}

interface Settings {
  user: UserSettings;
  team: {};
  recorder: RecorderSettings;
  plugin: RecorderSettings;
  recovery: {};
  billing: {};
  ai: {};
  usage: IUsage;
  ui: UISettings;
  integrations: IntegrationsSettings;
}

export type V2SettingsKeys = 'recorder' | 'plugin' | 'user' | 'ui';

export const getSettings = async <K extends V2SettingsKeys>(
  key: K
): Promise<Settings[K]> => {
  const response = await apiV2.get<IVFSResponse<Settings[K]>>(
    `settings/${key}`
  );
  return response.data.data;
};

export const newUpdateSettings = (
  key: SettingsKeys,
  newSettings: NewSettings
) => {
  return apiV2.post(`settings/${key}`, newSettings);
};

interface GetInitialDataResponseBody {
  user: UserData;
  team: {
    selectedTeam: TeamSpace;
    teams: TeamSpace[];
  };
  folders: FolderType[];
  packageProperties: PackageProperties;
  usage: IUsage;
  settings: {
    // user: UserSettings;
    recorder: RecorderSettings;
    ui: UISettings;
    plugin: RecorderSettings;
  };
  integrations: IntegrationsSettings;
}

export const getInitialDataV2 = async ({
  joinCode,
  zoomCode,
}: {
  joinCode?: string;
  zoomCode?: string;
} = {}) => {
  const queryParams = new URLSearchParams();
  if (joinCode) queryParams.append('joinCode', joinCode);
  if (zoomCode) queryParams.append('zoomCode', zoomCode);

  const response = await apiV2.get<IVFSResponse<GetInitialDataResponseBody>>(
    `/init${queryParams.toString() ? `?${queryParams.toString()}` : ''}`
  );

  const {
    folders,
    user,
    team,
    packageProperties,
    usage,
    settings,
    integrations,
  } = response.data.data;

  return {
    folders,
    user,
    team,
    packageProperties,
    usage,
    settings,
    integrations,
  };
};

export const userSignUpURLV2 = (data: ISignUpData) => {
  return apiV2.post<IVFSResponse<{ accessToken?: string }>>(
    'account/register',
    data
  );
};

export const userSignInURLV2 = async (options: {
  email: string;
  password: string;
}) => {
  try {
    const response = await apiV2.post<IVFSResponse<{ token?: string }>>(
      'account/login',
      options
    );
    const { token } = response.data.data || {};
    if (!token) {
      throw new Error('Failed to sign in');
    }
    return token;
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error('Failed to sign in');
  }
};

export const forgetPasswordV2 = ({ email }: { email: string }) => {
  return apiV2.post('account/password/reset/request', { email });
};

export const resetPasswordURLV2 = (data: ResetPasswordPayload) => {
  return apiV2.post('account/password/reset', data);
};

export interface IAggregatedData {
  teamspaceFiles: BaseResponse<{
    fileSystem: FileSystemEntityType[];
    fileSystemVersion: number;
  }>;
  profile: BaseResponse<UserData>;
  teamspaces: BaseResponse<TeamSpace[]>;
  selectedTeamspace: BaseResponse<TeamSpace>;
}

export const scheduleDeleteAccountURL = () => {
  return apiV2.put('account/deletion-schedule');
};

export const unscheduleDeleteAccountURL = () => {
  return apiV2.delete('account/deletion-schedule');
};

export const addUserTag = ({ key, value }: { key: string; value: string }) => {
  return apiV2.post('account/tag', { key, value });
};

export const updateProfile = (data: UpdateUserProfileData) => {
  return apiV2.put('account/profile', data);
};
