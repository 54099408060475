import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  useTheme,
  DialogActions,
  Button,
} from '@mui/material';
import { HelpCircle, X } from 'lucide-mui';

import {
  setNotes as setNotesInStore,
  setTitle as setTitleInStore,
} from '../recorderSlice';
import { TimeStampedLine } from '../../types';

const formatTime = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return `[${h.toString().padStart(2, '0')}:${m
    .toString()
    .padStart(2, '0')}:${s.toString().padStart(2, '0')}]`;
};

const Notepad = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [title, setTitle] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editableTitle, setEditableTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [lines, setLines] = useState<TimeStampedLine[]>([]);
  const [recordingStartTime] = useState(Date.now());
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const debouncedSetNotes = useCallback(
    debounce((notes0: TimeStampedLine[]) => {
      dispatch(setNotesInStore(notes0));
    }, 1000),
    [dispatch]
  );

  const commonTitleStyles = {
    mb: 2,
    color: 'text.secondary',
    fontSize: '1.1rem',
    fontWeight: 500,
    px: 1,
    py: 0.5,
    borderRadius: 1,
    height: '32px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newValue = e.target.value;

    // Auto-format bullet points
    newValue = newValue.replace(/^- /gm, '• '); // Convert "- " to "• " at start of lines
    // Convert "[] " or "[ ] " to "☐ "
    newValue = newValue.replace(/^\[[ ]?\] /gm, '☐ ');
    // Convert "[x] " or "[X] " to "☑ "
    newValue = newValue.replace(/^\[[xX]\] /gm, '☑ ');
    // Convert "!" at start of line to attention marker
    newValue = newValue.replace(/^! /gm, '⚠️ ');
    // Convert arrows
    newValue = newValue.replace(/->/g, '→');
    newValue = newValue.replace(/<-/g, '←');

    setNotes(newValue);

    // Split into lines and update timestamps
    const currentLines = newValue.split('\n');
    const updatedLines = currentLines.map((text, index) => {
      const existingLine = lines[index];
      if (existingLine && existingLine.text === text) {
        return existingLine;
      }
      return {
        text,
        timestamp: Math.floor((Date.now() - recordingStartTime) / 1000),
      };
    });

    setLines(updatedLines);
    debouncedSetNotes(updatedLines);

    // Log the timestamped version
    const timestampedText = updatedLines
      .map((line) => `${formatTime(line.timestamp)} ${line.text}`)
      .join('\n');
    console.log('Timestamped Notes:', timestampedText);
  };

  useEffect(() => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    setTitle(`New Recording ${formattedDate}`);
    setEditableTitle(`New Recording ${formattedDate}`);
  }, []);

  const handleTitleClick = () => {
    setIsEditing(true);
  };

  const handleTitleBlur = () => {
    setIsEditing(false);
    if (editableTitle.trim() === '') {
      const now = new Date();
      const formattedDate = now.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      const defaultTitle = `New Recording ${formattedDate}`;
      setTitle(defaultTitle);
      setEditableTitle(defaultTitle);
      dispatch(setTitleInStore(defaultTitle));
    } else {
      setTitle(editableTitle);
      dispatch(setTitleInStore(editableTitle));
    }
  };

  const handleTitleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          mb: 0.5,
          position: 'sticky',
          top: 0,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgb(0, 0, 0)'
              : 'rgb(255, 255, 255)',
          zIndex: 1,
        }}
      >
        {isEditing ? (
          <TextField
            fullWidth
            value={editableTitle}
            onChange={(e) => setEditableTitle(e.target.value)}
            onBlur={handleTitleBlur}
            onFocus={handleTitleFocus}
            variant="standard"
            autoFocus
            sx={{
              ...commonTitleStyles,
              backgroundColor: 'background.paper',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              '& .MuiInputBase-root': {
                p: 0,
                height: '100%',
              },
              '& .MuiInput-root:before, & .MuiInput-root:after': {
                display: 'none',
              },
              '& .MuiInputBase-input': {
                p: 0,
                height: '100% !important',
                color: 'text.primary',
                fontSize: '1.1rem',
                fontWeight: 500,
              },
            }}
          />
        ) : (
          <Typography
            onClick={handleTitleClick}
            sx={{
              ...commonTitleStyles,
              cursor: 'pointer',
              backgroundColor: 'background.paper',
              '&:hover': {
                color: 'text.primary',
                backgroundColor: 'action.hover',
              },
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          overflow: 'auto',
          borderRadius: 1,
        }}
      >
        <TextField
          multiline
          fullWidth
          minRows={5}
          maxRows={20}
          value={notes}
          onChange={handleNotesChange}
          placeholder="Write notes..."
          variant="outlined"
          sx={{
            flex: 1,
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'background.paper',
              borderRadius: 1,
              py: 0.5,
              px: 0,
              '& textarea': {
                overflow: 'auto !important',
                borderRadius: 1,
                px: '8px !important',
                py: '8px !important',
              },
              '& fieldset': {
                border: 'none',
                borderRadius: 1,
              },
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              '&.Mui-focused': {
                backgroundColor: 'action.hover',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
            '& .MuiInputBase-input': {
              color: 'text.primary',
              fontSize: '1rem',
              '&::placeholder': {
                color: 'text.secondary',
                opacity: 1,
              },
            },
          }}
        />
        <IconButton
          onClick={() => setIsHelpOpen(true)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.secondary',
            padding: '4px',
            '&:hover': {
              opacity: 0.5,
            },
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
            },
          }}
          aria-label="Learn more about note-taking"
        >
          <HelpCircle />
        </IconButton>
      </Box>

      <Dialog
        open={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        aria-labelledby="notes-help-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="notes-help-dialog-title" sx={{ pb: 1 }}>
          About Note Taking
          <IconButton
            aria-label="close"
            onClick={() => setIsHelpOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'text.secondary',
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              mb: 2,
              color: 'text.primary',
              '& p': { mb: 2 },
            }}
          >
            <p>
              As you record, use this space to take notes about important
              moments. Each line you write is automatically timestamped, making
              it easy to find key points later.
            </p>
            <p>
              Focus on writing what&apos;s meaningful to you - key decisions,
              important details, or things you want to follow up on. Don&apos;t
              worry about catching everything.
            </p>
            <p>
              After recording, you can use AI to help expand your notes, fill in
              gaps, and generate summaries based on the moments you marked as
              important.
            </p>
            <p>
              <strong>Formatting Shortcuts:</strong>
            </p>
            <ul style={{ marginTop: '-8px' }}>
              <li>Type &quot;- &quot; to create a bullet point (•)</li>
              <li>
                Type &quot;[] &quot; or &quot;[ ] &quot; to create an empty
                checkbox (☐)
              </li>
              <li>Type &quot;[x] &quot; to create a checked checkbox (☑)</li>
              <li>Type &quot;! &quot; to create a warning marker (⚠️)</li>
              <li>Type &quot;-&gt;&quot; for a right arrow (→)</li>
              <li>Type &quot;&lt;-&quot; for a left arrow (←)</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsHelpOpen(false)}
            variant="contained"
            sx={{ px: 3 }}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Notepad;
