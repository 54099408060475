export default class AudioStreamMerger {
  private audioContext: AudioContext;

  private destination: MediaStreamAudioDestinationNode;

  private inputStreams: MediaStream[];

  private sourceNodes: MediaStreamAudioSourceNode[];

  private outputStream: MediaStream;

  private merger: ChannelMergerNode;

  constructor() {
    this.audioContext = new AudioContext();
    this.destination = this.audioContext.createMediaStreamDestination();
    this.inputStreams = [];
    this.sourceNodes = [];
    this.outputStream = this.destination.stream;
    this.merger = this.audioContext.createChannelMerger(1);
  }

  async addStream(stream: MediaStream) {
    if (stream.getAudioTracks().length > 0) {
      this.inputStreams.push(stream);
      await this.updateTracks();
    }
  }

  removeStream(stream: MediaStream) {
    this.inputStreams = this.inputStreams.filter((s) => s !== stream);
    this.updateTracks();
  }

  private async updateTracks() {
    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume();
    }

    // Disconnect existing nodes
    this.sourceNodes.forEach((node) => node.disconnect());
    this.sourceNodes = [];

    // Add new nodes
    this.inputStreams
      .filter((stream) => stream.getAudioTracks().length > 0)
      .forEach((stream) => {
        const sourceNode = this.audioContext.createMediaStreamSource(stream);
        sourceNode.connect(this.merger);
        this.sourceNodes.push(sourceNode);
      });

    // Connect merger to destination if there are tracks
    if (this.sourceNodes.length > 0) {
      this.merger.connect(this.destination);
    } else {
      this.merger.disconnect();
    }
  }

  getOutputStream(): MediaStream {
    return this.outputStream;
  }

  stop() {
    // Disconnect and close audio context
    this.sourceNodes.forEach((node) => node.disconnect());
    this.merger.disconnect();
    this.audioContext.close();

    // Clear properties
    this.inputStreams = [];
    this.sourceNodes = [];
    this.outputStream.getTracks().forEach((track) => {
      track.stop();
      this.outputStream.removeTrack(track);
    });
  }
}
