import { v4 as uuid } from 'uuid';
import { config } from '../../config';
import { FeatureFlagsType } from '../constants/featureFlags';

const FEATURE_FLAGS_KEY = 'featureFlags';
const TOKEN_KEY = 'screenapp_token';
const TOKEN_EXPIRY_KEY = 'screenapp_token_expiry';

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setFeatureFlags = (data: FeatureFlagsType) => {
  localStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(data));
};
export const loadFeatureFlags = (): FeatureFlagsType => {
  const featureFlags = localStorage.getItem(FEATURE_FLAGS_KEY);
  return featureFlags ? JSON.parse(featureFlags) : {};
};

const MINUTE = 1000 * 60;
const DAY = MINUTE * 60 * 24;

export const shouldRefreshToken = () => {
  const now = Date.now();
  const expiry = window.localStorage.getItem(TOKEN_EXPIRY_KEY);
  // Refresh tokens expiring within the next day, but not the already-expired ones.
  return expiry ? +expiry <= now + DAY && +expiry > now : false;
};

export const setToken = (token: string) => {
  if (token) {
    window.localStorage.setItem(TOKEN_KEY, token);
    window.localStorage.setItem(
      TOKEN_EXPIRY_KEY,
      (Date.now() + config.jwtExpiration).toString()
    );
  }
};

export const removeToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(TOKEN_EXPIRY_KEY);
};

export const getOrCreateLocalTrackingId = () => {
  const ltidStored = localStorage.getItem('ltid');
  if (ltidStored === null) {
    const ltid = uuid();
    localStorage.setItem('ltid', ltid);
    return ltid;
  }

  return ltidStored;
};
