export const getCustomLogger = (name: string) => {
  return {
    info: (message: string, ...rest: any[]) =>
      console.log(`${name}: ${message}`, ...rest),
    error: (message: string, ...rest: any[]) =>
      console.error(`${name}: ${message}`, ...rest),
    warn: (message: string, ...rest: any[]) =>
      console.warn(`${name}: ${message}`, ...rest),
  };
};

export default console;
