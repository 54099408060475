import type { CSSObject } from '@mui/material/styles';
import Grow from '@mui/material/Grow';

type SharedStyleOverrides = {
  [key: string]: any;
};

export const brandColors = {
  primaryMain: '#1E90FF',
  primaryLight: '#60B2FF',
  primaryDark: '#0066CC',
  textDark: '#363739',
  textLight: '#E4E6EB',
  interactiveLight: '#4B4D52',
  interactiveDark: '#CDCFD3',
} as const;

export const sharedStyleOverrides: SharedStyleOverrides = {
  '*::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '*::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '*': {
    scrollbarWidth: 'thin',
  },
  body: {
    touchAction: 'manipulation',
  },
  'input, textarea, select': {
    fontSize: '16px !important',
    '-webkit-text-size-adjust': 'none',
  },
};

export const sharedSvgIconStyles: CSSObject = {
  fontSize: '1rem',
  '&:active': {
    transform: 'translateY(1px)',
    transition: 'transform 0.1s',
  },
};

export const sharedComponents = {
  MuiCssBaseline: {
    styleOverrides: sharedStyleOverrides,
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: sharedSvgIconStyles,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '9999px',
        '&:active': {
          transform: 'translateY(1px)',
          transition: 'transform 0.1s',
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          transform: 'translateY(1px)',
          transition: 'transform 0.1s',
        },
      },
      containedError: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          transform: 'translateY(1px)',
          transition: 'transform 0.1s',
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        border: 'none',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        '&.MuiListItemButton-gutters': {
          paddingLeft: 8 * 1.5,
          paddingRight: 0,
        },
        '& .MuiListItemIcon-root': {
          marginRight: '12px',
        },
        '&:active': {
          transform: 'translateY(1px)',
          transition: 'transform 0.1s',
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: '1.5rem',
        height: '1.5rem',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        height: '1.5rem',
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        '&.MuiChip-outlinedSecondary': {
          borderRadius: 1,
          cursor: 'pointer',
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'left',
          my: 1,
          height: 'auto',
          maxHeight: '32px',
          '& .MuiChip-label': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)',
            padding: '4px',
          },
          transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)',
          transform: 'scale(1)',
          '&:hover': {
            transform: 'scale(1.02)',
            maxHeight: '200px',
            '& .MuiChip-label': {
              whiteSpace: 'normal',
              overflow: 'visible',
              textOverflow: 'clip',
            },
          },
          '&:active': {
            transform: 'scale(0.98)',
            transition: 'all 100ms cubic-bezier(0.4, 0, 0.2, 1)',
          },
        },
      },
    },
  },
  MuiTab: {
    defaultProps: {},
    styleOverrides: {
      root: () => ({
        textTransform: 'none',
        variants: [],
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      },
      icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '12px',
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      TransitionComponent: Grow,
      TransitionProps: {
        timeout: 300,
      },
    },
    styleOverrides: {
      paper: {
        borderRadius: '8px',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&:active': {
          transform: 'translateY(1px)',
          transition: 'transform 0.1s',
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: '8px',
        boxShadow: '0px 2px 6px rgba(16, 24, 40, 0.03)',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiAutocomplete-inputRoot': {
          padding: '0',
        },
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        height: 8,
      },
      bar: {
        borderRadius: 4,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
} as const;

export const sharedTypography = {
  fontFamily: 'Inter',
  h1: {
    fontSize: '3.052rem',
  },
  h2: {
    fontSize: '2.441rem',
  },
  h3: {
    fontSize: '1.953rem',
  },
  h4: {
    fontSize: '1.563rem',
  },
  h5: {
    fontSize: '1.25rem',
  },
  h6: {
    fontSize: '1rem',
  },
  body1: {
    fontSize: '0.875rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 'normal',
    lineHeight: '1.5rem',
    letterSpacing: '0em',
    textAlign: 'left',
  },
} as const;
