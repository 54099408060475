import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { streams } from '../utils/streamUtils';
import { RecorderState } from '../../store';

const DisplayPreview = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { mediaStreams } = useSelector(
    (state: RecorderState) => state.recorder
  );

  const hasVideoTracks =
    streams.displayMediaStream?.getVideoTracks().length > 0;

  useEffect(() => {
    const stream = streams.displayMediaStream;
    if (videoRef.current && stream && hasVideoTracks) {
      videoRef.current.srcObject = stream;
      videoRef.current.muted = true;
      videoRef.current.play();
    }
  }, [mediaStreams, hasVideoTracks]);

  // Only render if there are actual video tracks
  if (!hasVideoTracks) {
    return null;
  }

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return (
    <Box
      component="video"
      ref={videoRef}
      sx={{
        maxWidth: '40rem',
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        overflow: 'hidden',
        objectFit: 'contain',
        ml: 1,
      }}
    />
  );
};

export default DisplayPreview;
