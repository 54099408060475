/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint no-param-reassign: "error" */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  initializeMultipartUpload,
  createPartUploadUrl,
  finalizeUpload as finalizeMultipartUpload,
  uploadChunkToS3,
} from './uploadService';
import Recorder from './Recorder/utils/recorderLogic';
import { RecorderState } from './store';
import {
  cleanup,
  getDisplayMediaStream,
  getUserMediaStream,
  streams,
} from './Recorder/utils/streamUtils';
import logger from '../lib/logger';
import { FileType } from '../types';
import { addBackupRecord, saveChunk } from '../lib/recovery';

let recorder: Recorder | undefined;
export const getRecorder = () => recorder;

const getSerializedDevice = ({
  deviceId,
  groupId,
  label,
  kind,
}: MediaDeviceInfo) => ({
  deviceId,
  groupId,
  label,
  kind,
});

async function checkMediaPermissions() {
  try {
    const [cameraPerm, microphonePerm] = await Promise.all([
      navigator.permissions.query({
        name: 'camera' as any,
      }),
      navigator.permissions.query({
        name: 'microphone' as any,
      }),
    ]);
    return {
      camera: cameraPerm.state,
      microphone: microphonePerm.state,
    };
  } catch (error) {
    return { camera: 'prompt', microphone: 'prompt' };
  }
}

/**
 * For device enumeration to work, the permission must have been already
 * obtained by calling navigator.mediaDevices.getUserMedia.
 * This function is a hack to get the devices to enumerate.
 *
 * @param camera - Whether the camera is requested
 * @param microphone - Whether the microphone is requested
 */
async function enumerateDevicesHack({
  camera,
  microphone,
}: {
  camera: boolean;
  microphone: boolean;
}): Promise<void> {
  const {
    camera: cameraPermission,
    microphone: microphonePermission,
  } = await checkMediaPermissions();
  const toRequest: Record<string, boolean> = {};
  if (camera && cameraPermission === 'prompt') {
    toRequest.video = true;
  }
  if (microphone && microphonePermission === 'prompt') {
    toRequest.audio = true;
  }
  if (Object.keys(toRequest).length === 0) {
    return;
  }

  try {
    const obtainedStreams = await navigator.mediaDevices.getUserMedia(
      toRequest
    );
    if (obtainedStreams) {
      obtainedStreams.getTracks().forEach((track) => track.stop());
    }
  } catch (error) {
    if (toRequest.video && toRequest.audio) {
      // If both video and audio are requested, try to enumerate devices without one of them
      await Promise.all([
        enumerateDevicesHack({ camera: false, microphone: true }),
        enumerateDevicesHack({ camera: true, microphone: false }),
      ]);
    }
  }
}

export const fetchAvailableDevices = createAsyncThunk(
  'recorder/devices/fetch',
  async (
    { camera, microphone }: { camera: boolean; microphone: boolean } = {
      camera: true,
      microphone: true,
    },
    { rejectWithValue }
  ) => {
    try {
      await enumerateDevicesHack({ camera, microphone });

      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoinput = devices
        .filter((device) => device.kind === 'videoinput')
        .map(getSerializedDevice);
      const audioinput = devices
        .filter((device) => device.kind === 'audioinput')
        .map(getSerializedDevice);
      return { videoinput, audioinput };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserMediaThunk = createAsyncThunk(
  'recorder/userMedia/fetch',
  async (
    constraints: Parameters<typeof navigator.mediaDevices.getUserMedia>[0]
  ): Promise<{
    streamId: string;
    trackInfo: Pick<MediaStreamTrack, 'id' | 'kind' | 'label'>[];
  }> => {
    try {
      const { streamId, trackInfo } = await getUserMediaStream(constraints);
      return { streamId, trackInfo };
    } catch (error) {
      logger.error('Error fetching user media', error);
      throw error;
    }
  }
);

export const fetchDisplayMediaThunk = createAsyncThunk(
  'recorder/displayMedia/fetch',
  async (
    displayMediaConstraints: Parameters<MediaDevices['getDisplayMedia']>[0],
    { dispatch }
  ) => {
    try {
      const { streamId, trackInfo } = await getDisplayMediaStream(
        displayMediaConstraints
      );
      return { streamId, trackInfo };
    } catch (error) {
      logger.log('Error fetching display media', error);
      throw error;
    }
  }
);

export const initializeUploadThunk = createAsyncThunk(
  'recorder/upload/initialize',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        teamId,
        folderId,
        contentType,
      } = (getState() as RecorderState).recorder.upload;
      if (!teamId || !folderId || !contentType)
        throw new Error('Missing required parameters');

      const response = await initializeMultipartUpload({
        teamId,
        folderId,
        contentType,
      });
      const { fileId, uploadId } = response;

      return {
        fileId,
        uploadId,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBackupRecordThunk = createAsyncThunk(
  'recorder/recording/backup/create',
  async (_, { getState }) => {
    try {
      const state = getState() as RecorderState;
      const { teamId, folderId, fileId, uploadId } = state.recorder.upload;

      await addBackupRecord(fileId!, {
        teamId: teamId || undefined,
        folderId: folderId || undefined,
        fileId: fileId!,
        uploadId: uploadId || undefined,
        startedAt: Date.now(),
      });
    } catch (error) {
      console.error(error);
    }

    return true;
  }
);

export const startRecordingThunk = createAsyncThunk(
  'recorder/recording/start',
  async (_, { dispatch, getState }) => {
    if (recorder) {
      await recorder.stopRecording();
      throw new Error('Recorder already exists. Cannot start a new recording');
    }
    const { upload } = (getState() as RecorderState).recorder;
    const { userMediaStream, displayMediaStream } = streams;
    const { contentType } = upload;
    if (!userMediaStream || !displayMediaStream || !contentType) {
      throw new Error(
        'Missing camera/display streams or contentType to start recording'
      );
    }
    recorder = new Recorder({
      contentType,
    });
    await recorder.startRecording();
  }
);

// Dispatch calls to upload service logic
export const uploadChunks = createAsyncThunk(
  'recorder/upload/chunk',
  async (
    {
      blob,
      partNumber,
    }: {
      blob: Blob;
      partNumber: number;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as RecorderState;
    const {
      teamId,
      folderId,
      fileId,
      uploadId,
      contentType,
    } = state.recorder.upload;
    if (!teamId || !folderId || !fileId || !uploadId) {
      throw new Error(
        'Missing required parameters (teamId, folderId, fileId, uploadId). Cannot upload chunks.'
      );
    }

    let retries = 0;
    const maxRetries = 5;
    let success = false;
    /* eslint-disable no-await-in-loop */
    while (retries < maxRetries && !success) {
      try {
        const url = await createPartUploadUrl({
          teamId: teamId!,
          folderId: folderId!,
          uploadId: uploadId!,
          fileId: fileId!,
          partNumber,
          contentType: contentType!,
        });
        if (!url) throw new Error('No upload URL provided');

        await uploadChunkToS3({ uploadUrl: url, chunk: blob });
        success = true;
      } catch (error) {
        retries += 1;
        if (retries === maxRetries) {
          throw error;
        }
        // Wait for a short time before retrying
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
    /* eslint-enable no-await-in-loop */
  }
);

export const backupChunkThunk = createAsyncThunk(
  'recorder/recording/backup/chunk',
  async (
    {
      blob,
      partNumber,
    }: {
      blob: Blob;
      partNumber: number;
    },
    { getState }
  ) => {
    const state = getState() as RecorderState;

    try {
      const { fileId } = state.recorder.upload;
      await saveChunk(fileId!, partNumber, blob);
    } catch (error) {
      console.error(error);
    }

    return true;
  }
);

export const pauseRecordingThunk = createAsyncThunk(
  'recorder/recording/pause',
  async () => {
    if (recorder) {
      recorder.pauseRecording();
    }
  }
);

export const resumeRecordingThunk = createAsyncThunk(
  'recorder/recording/resume',
  async () => {
    if (recorder) {
      recorder.resumeRecording();
    }
  }
);

export const stopRecordingThunk = createAsyncThunk(
  'recorder/recording/stop',
  async (_, { dispatch }) => {
    if (recorder) {
      await recorder.stopRecording();
      recorder = undefined;
    }
  }
);

export const finalizeUpload = createAsyncThunk(
  'recorder/upload/finalize',
  async (_, { getState }): Promise<FileType> => {
    const state = getState() as RecorderState;
    const {
      teamId,
      folderId,
      fileId,
      uploadId,
      contentType,
      notes,
      title,
    } = state.recorder.upload;

    if (!teamId || !folderId || !fileId || !uploadId || !contentType) {
      console.error(
        `Missing required parameters (teamId, folderId, fileId, uploadId, contentType). Cannot finalize upload. ${JSON.stringify(
          state.recorder.upload
        )}`
      );
    }

    let attempts = 0;
    const maxAttempts = 5;
    /* eslint-disable no-await-in-loop */
    while (attempts < maxAttempts) {
      try {
        const file = await finalizeMultipartUpload({
          teamId: teamId!,
          folderId: folderId!,
          fileId: fileId!,
          uploadId: uploadId!,
          contentType: contentType!,
          notes,
          title,
        });
        return file;
      } catch (error) {
        attempts += 1;
        if (attempts >= maxAttempts) {
          throw error;
        }
        // Wait for a short time before retrying
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
    /* eslint-enable no-await-in-loop */

    throw new Error('Failed to finalize upload');
  }
);

export const cancelThunk = createAsyncThunk('recorder/cancelThunk', () => {
  cleanup();
});
