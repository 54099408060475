/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  ContentType,
  getMostSuitableContentType,
} from '../constants/mediaFormatProfiles';
import { requestStatusType, TimeStampedLine } from '../types';
import {
  cancelThunk,
  fetchAvailableDevices,
  fetchUserMediaThunk,
  finalizeUpload,
  initializeUploadThunk,
  pauseRecordingThunk,
  resumeRecordingThunk,
  startRecordingThunk,
  stopRecordingThunk,
} from './thunk';

export type DisplaySurface = 'none' | 'monitor' | 'window' | 'browser';

interface AudioSettings {
  browserAudio: boolean;
  microphoneAudio: boolean;
}

interface VideoSettings {
  webCam: boolean;
  screen: boolean;
}

interface RecordingControlSettings {
  autoDownload: boolean;
  maxRecordingTimeMins: number;
}

interface UISettings {
  hideLogo: boolean;
  recorderButtonText: string;
  position: 'BOTTOM_LEFT' | 'BOTTOM_RIGHT';
  helpText: string;
  identificationForm: boolean;
}

export interface RecorderSettings {
  audioSettings: AudioSettings;
  videoSettings: VideoSettings;
  recordingControlSettings: RecordingControlSettings;
  uiSettings: UISettings;
}

interface UploadState {
  teamId: string | null; // Need teamId and folderId to get upload Id
  folderId: string | null; // Need teamId and folderId to get upload Id
  contentType: ContentType | null; // Need contentType to get upload Id
  uploadId: string | null; // Receives this from the backend
  fileId: string | null; // Receives this from the backend
  status: requestStatusType;
  error: string | null;
  notes: TimeStampedLine[];
  /** Same as the fileName */
  title: string;
}

type DeviceInfo = Pick<
  MediaDeviceInfo,
  'deviceId' | 'groupId' | 'label' | 'kind'
>;
interface AvailableDevicesState {
  status: requestStatusType;
  videoinput: DeviceInfo[];
  audioinput: DeviceInfo[];
  error: string | null;
}

interface SelectedDeviceType extends Pick<DeviceInfo, 'deviceId'> {
  isActive: boolean;
}

interface RecorderState {
  status:
    | 'initializing'
    | 'ready'
    | 'recording'
    | 'paused'
    | 'stopped' // Recoder is stopped, but not completed uploading recording
    | 'uploaded' // completed uploading recording
    | 'failed'
    | 'saving';
  parentElementSelector: string;
  settings: RecorderSettings;
  upload: UploadState;
  availableDevices: AvailableDevicesState;
  selectedDevices: {
    videoinput: SelectedDeviceType;
    audioinput: SelectedDeviceType;
    displaySurface: DisplaySurface;
  };
  mediaStreams: {
    userMedia: {
      status: requestStatusType;
      streamId: string | undefined;
      trackInfo: Pick<MediaStreamTrack, 'id' | 'kind' | 'label'>[];
    };
    displayMedia: {
      status: requestStatusType;
    };
  };
  timer: number;
  controls: {
    isFullscreen: boolean;
    uiMode: 'fullscreen' | 'popup';
    isMountedFromPlugin: boolean;
  };
}

const initialUploadState: UploadState = {
  teamId: null,
  folderId: null,
  contentType: getMostSuitableContentType(),
  uploadId: null,
  fileId: null,
  status: 'idle',
  error: null,
  notes: [],
  title: '',
};

const initialAvailableDevicesState: AvailableDevicesState = {
  status: 'idle',
  videoinput: [],
  audioinput: [],
  error: null,
};

const initialState: RecorderState = {
  status: 'initializing',
  parentElementSelector: '#screenapp-recorder',
  settings: {
    audioSettings: {
      browserAudio: true,
      microphoneAudio: true,
    },
    videoSettings: {
      webCam: true,
      screen: true,
    },
    recordingControlSettings: {
      autoDownload: false,
      maxRecordingTimeMins: 60,
    },
    uiSettings: {
      hideLogo: false,
      recorderButtonText: 'Start Recording',
      position: 'BOTTOM_RIGHT',
      helpText: 'Click to start recording',
      identificationForm: false,
    },
  },
  upload: initialUploadState,
  availableDevices: initialAvailableDevicesState,
  selectedDevices: {
    videoinput: {
      deviceId: 'none',
      isActive: false,
    },
    audioinput: {
      deviceId: 'none',
      isActive: false,
    },
    displaySurface: 'browser',
  },
  mediaStreams: {
    userMedia: {
      status: 'idle',
      streamId: undefined,
      trackInfo: [],
    },
    displayMedia: {
      status: 'idle',
    },
  },
  timer: 0,
  controls: {
    isFullscreen: false,
    uiMode: 'fullscreen',
    isMountedFromPlugin: false,
  },
};

const recorderSlice = createSlice({
  name: 'recorder',
  initialState,
  reducers: {
    setupState(
      state,
      action: PayloadAction<{
        parentElementSelector: string;
        uploadState: Pick<UploadState, 'teamId' | 'folderId'>;
        settings?: Partial<
          Pick<RecorderSettings, 'audioSettings' | 'videoSettings'>
        >;
      }>
    ) {
      // This is called from external scripts to setup the store
      // Explicitly setting the teamId, folderId to avoid any issues
      state.parentElementSelector = action.payload.parentElementSelector;
      const { teamId, folderId } = action.payload.uploadState;
      state.upload.teamId = teamId;
      state.upload.folderId = folderId;
      state.settings = {
        ...state.settings,
        ...action.payload.settings,
      };
      state.status = 'ready';
    },
    updateSelectedDevices(
      state,
      action: PayloadAction<{
        videoinput?: string;
        audioinput?: string;
      }>
    ) {
      if (action.payload.videoinput) {
        state.selectedDevices.videoinput.deviceId = action.payload.videoinput;
      }
      if (action.payload.audioinput) {
        state.selectedDevices.audioinput.deviceId = action.payload.audioinput;
      }
    },
    setDisplaySurface(state, action: PayloadAction<DisplaySurface>) {
      // Retrieve selected devices from localStorage
      const storedSelectedDevices = localStorage.getItem('recorderSettings');
      const localSelectedDevices = storedSelectedDevices
        ? JSON.parse(storedSelectedDevices)
        : {};
      const { screen } = state.settings.videoSettings;
      if (!screen) {
        state.selectedDevices.displaySurface = 'none';
      } else if (action.payload) {
        state.selectedDevices.displaySurface = action.payload;
      } else if (localSelectedDevices?.selectedDevices?.displaySurface) {
        // Use the displaySurface from localStorage if available
        state.selectedDevices.displaySurface =
          localSelectedDevices.selectedDevices.displaySurface;
      }
    },
    setError(state, action: PayloadAction<string>) {
      state.upload.error = action.payload;
      state.upload.status = 'failed';
    },
    startTimer(state) {
      state.timer = 0;
    },
    incrementTimer(state) {
      state.timer += 1;
    },
    resetTimer(state) {
      state.timer = 0;
    },
    setControlsFullscreen(state, action: PayloadAction<boolean>) {
      state.controls.isFullscreen = action.payload;
    },
    setUIMode(state, action: PayloadAction<'fullscreen' | 'popup'>) {
      state.controls.uiMode = action.payload;
    },
    setMountedFromPlugin(state, action: PayloadAction<boolean>) {
      state.controls.isMountedFromPlugin = action.payload;
    },
    setNotes(state, action: PayloadAction<TimeStampedLine[]>) {
      state.upload.notes = action.payload;
    },
    /**
     * Set the title (fileName) of the recording
     * @param state
     * @param action
     */
    setTitle(state, action: PayloadAction<string>) {
      state.upload.title = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableDevices.pending, (state) => {
        state.availableDevices.status = 'pending';
      })
      .addCase(fetchAvailableDevices.fulfilled, (state, action) => {
        const { videoinput, audioinput } = action.payload;
        state.availableDevices = {
          status: 'succeeded',
          videoinput,
          audioinput,
          error: null,
        };
        const { webCam, screen } = state.settings.videoSettings;
        const { microphoneAudio } = state.settings.audioSettings;
        const currentWebcamId = state.selectedDevices.videoinput.deviceId;
        const currentMicrophoneId = state.selectedDevices.audioinput.deviceId;

        // Retrieve selected devices from localStorage
        const storedSelectedDevices = localStorage.getItem('recorderSettings');
        const localSelectedDevices = storedSelectedDevices
          ? JSON.parse(storedSelectedDevices)
          : {};

        // If webcam is disabled
        if (!webCam) {
          state.selectedDevices.videoinput.deviceId = 'none';
        } else if (
          localSelectedDevices?.selectedDevices?.videoinput &&
          (localSelectedDevices?.selectedDevices?.videoinput === 'none' ||
            videoinput.find(
              (device) =>
                device.deviceId ===
                localSelectedDevices.selectedDevices.videoinput
            ))
        ) {
          // Use the device from localStorage if valid
          state.selectedDevices.videoinput.deviceId =
            localSelectedDevices.selectedDevices.videoinput;
        } else if (
          !videoinput.find((device) => device.deviceId === currentWebcamId)
        ) {
          state.selectedDevices.videoinput.deviceId =
            videoinput[0]?.deviceId || '';
        }
        // If microphone is disabled
        if (!microphoneAudio) {
          state.selectedDevices.audioinput.deviceId = 'none';
        } else if (
          localSelectedDevices?.selectedDevices?.audioinput &&
          (localSelectedDevices?.selectedDevices?.audioinput === 'none' ||
            audioinput.find(
              (device) =>
                device.deviceId ===
                localSelectedDevices.selectedDevices.audioinput
            ))
        ) {
          // Use the device from localStorage if valid
          state.selectedDevices.audioinput.deviceId =
            localSelectedDevices.selectedDevices.audioinput;
        } else if (
          !audioinput.find((device) => device.deviceId === currentMicrophoneId)
        ) {
          state.selectedDevices.audioinput.deviceId =
            audioinput[0]?.deviceId || '';
        }
        // If screen is disabled
        if (!screen) {
          state.selectedDevices.displaySurface = 'none';
        } else if (localSelectedDevices?.selectedDevices?.displaySurface) {
          // Use the displaySurface from localStorage if available
          state.selectedDevices.displaySurface =
            localSelectedDevices.selectedDevices.displaySurface;
        }
      })
      .addCase(fetchAvailableDevices.rejected, (state, action) => {
        state.availableDevices = {
          status: 'failed',
          videoinput: [],
          audioinput: [],
          error: action.payload as string,
        };
      })
      .addCase(fetchUserMediaThunk.pending, (state) => {
        state.mediaStreams.userMedia.status = 'pending';
      })
      .addCase(fetchUserMediaThunk.fulfilled, (state, action) => {
        const { streamId, trackInfo } = action.payload;
        state.mediaStreams.userMedia.streamId = streamId;
        state.mediaStreams.userMedia.trackInfo = trackInfo;
        state.mediaStreams.userMedia.status = 'succeeded';
      })
      .addCase(fetchUserMediaThunk.rejected, (state) => {
        state.mediaStreams.userMedia.status = 'failed';
      })
      .addCase(initializeUploadThunk.fulfilled, (state, action) => {
        const { fileId, uploadId } = action.payload;
        state.upload.fileId = fileId;
        state.upload.uploadId = uploadId;
        state.status = 'ready';
      })
      .addCase(initializeUploadThunk.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(startRecordingThunk.fulfilled, (state) => {
        state.status = 'recording';
      })
      .addCase(startRecordingThunk.rejected, (state) => {
        state.status = 'failed';
      })
      // Cleanup
      .addCase(cancelThunk.fulfilled, (state) => {
        state.status = 'ready';
      })
      // Pause
      .addCase(pauseRecordingThunk.fulfilled, (state) => {
        state.status = 'paused';
      })
      // Resume
      .addCase(resumeRecordingThunk.fulfilled, (state) => {
        state.status = 'recording';
      })
      // Stop
      .addCase(stopRecordingThunk.pending, (state) => {
        state.status = 'saving';
      })
      // Finalize upload
      .addCase(finalizeUpload.fulfilled, (state) => {
        state.status = 'uploaded';
      })
      .addCase(finalizeUpload.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setupState,
  updateSelectedDevices,
  setDisplaySurface,
  setError,
  incrementTimer,
  setControlsFullscreen,
  setUIMode,
  setMountedFromPlugin,
  setNotes,
  setTitle,
} = recorderSlice.actions;
export default recorderSlice.reducer;
