export const ONBOARDING_STEP_TAG_KEY = 'onboardingStep';
export const ONBOARDING_STEP_TAG_VALUE = '2024-Dec';
export const CHROME_EXTENSION_TAG_KEY = 'chrome-extension-clicked';
export const CHROME_EXTENSION_TAG_VALUE = 'true';
export const CHANGELOG_SEEN_TAG_KEY = 'changelog-seen-version';
export const VIDEO_LEFT_COLUMN_WIDTH_KEY = 'video-left-column-width';
export const LIBRARY_VIEW_TYPE_KEY = 'library-view-type';
export const CHAPTERS_TIMESTAMPS_VISIBLE_KEY = 'chapters-timestamps-visible';
export const CHAPTERS_EXPANDED_KEY = 'chapters-expanded';
export const VIDEO_CHAPTERS_VISIBLE_KEY = 'video-chapters-visible';
export const VIDEO_CAPTIONS_VISIBLE_KEY = 'video-captions-visible';
export const NOTEPAD_VISIBLE_KEY = 'notepad-visible';
export const MULTI_ASK_AI_LAST_OUTPUT_KEY = 'multi-ask-ai-last-output';
export const LIBRARY_SORT_BY_KEY = 'library-sort-by';
export const LIBRARY_SORT_ORDER_KEY = 'library-sort-order';
export const TEAM_LOGO_URL_KEY = 'team_logo_url';
