import type { Action, ThunkDispatch } from '@reduxjs/toolkit';
import {
  ContentType,
  guessProfileFromMime,
  getMostSuitableContentType,
  getMimeWithCodecs,
} from '../../../constants/mediaFormatProfiles';
import { getStore } from '../../store';
import {
  uploadChunks,
  finalizeUpload,
  backupChunkThunk,
  stopRecordingThunk,
} from '../../thunk';
import { cleanup, streams, mergeStreams } from './streamUtils';
import logger from '../../../lib/logger';

type AppDispatch = ThunkDispatch<any, any, Action<string>>;

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB - Fixed chunk size for R2 compatibility
const TIMESLICE = 1000; // 1 second

class Recorder {
  private mediaRecorder: MediaRecorder | null = null;

  private buffer: Blob[] = [];

  private bufferSize = 0;

  private nextPartNumber = 1;

  private contentType: ContentType = getMostSuitableContentType();

  private finalizationPromise: Promise<void> | null = null;

  constructor({ contentType }: { contentType: ContentType }) {
    this.contentType = contentType;
  }

  private async uploadChunk(chunks: Blob[]) {
    const blob = new Blob(chunks, { type: this.contentType });
    logger.info(
      '>>> Uploading part',
      this.nextPartNumber,
      'size:',
      blob.size,
      'bytes'
    );

    const store = getStore();
    const { dispatch }: { dispatch: AppDispatch } = store;

    // Wait for both backup and upload to complete
    await Promise.all([
      dispatch(backupChunkThunk({ blob, partNumber: this.nextPartNumber })),
      dispatch(uploadChunks({ blob, partNumber: this.nextPartNumber })),
    ]);

    this.nextPartNumber += 1;
  }

  private async finalize() {
    if (this.finalizationPromise) {
      return this.finalizationPromise;
    }

    this.finalizationPromise = (async () => {
      try {
        const store = getStore();
        const { dispatch }: { dispatch: AppDispatch } = store;
        await dispatch(finalizeUpload());
      } catch (error) {
        logger.error('>>> Finalization failed:', error);
        throw error;
      }
    })();

    return this.finalizationPromise;
  }

  private async processAndUploadChunks(newData?: Blob) {
    if (newData) {
      this.buffer.push(newData);
      this.bufferSize += newData.size;
      logger.debug('>>> Buffer size after new data:', this.bufferSize, 'bytes');
    }

    const completeChunks: Blob[][] = [];

    // Extract all complete chunks
    while (this.bufferSize >= CHUNK_SIZE) {
      const chunk: Blob[] = [];
      let chunkSize = 0;

      while (chunkSize < CHUNK_SIZE && this.buffer.length > 0) {
        const nextBlob = this.buffer[0];
        const remainingNeeded = CHUNK_SIZE - chunkSize;

        if (chunkSize + nextBlob.size <= CHUNK_SIZE) {
          // Use entire blob
          chunk.push(this.buffer.shift()!);
          chunkSize += nextBlob.size;
        } else {
          // Split the blob
          chunk.push(nextBlob.slice(0, remainingNeeded));
          this.buffer[0] = nextBlob.slice(remainingNeeded);
          chunkSize += remainingNeeded;
        }
      }

      this.bufferSize -= CHUNK_SIZE;
      logger.debug('>>> Created chunk of size:', chunkSize, 'bytes');
      completeChunks.push(chunk);
    }

    // Upload chunks sequentially
    await completeChunks.reduce(async (promise, chunk) => {
      await promise;
      return this.uploadChunk(chunk);
    }, Promise.resolve());
  }

  private handleDataAvailable = async (event: BlobEvent) => {
    if (event.data.size > 0) {
      await this.processAndUploadChunks(event.data);
    }
  };

  private handleStop = async () => {
    logger.info('>>> Recording stopped');

    // Upload any remaining data
    if (this.buffer.length > 0) {
      logger.info('>>> Uploading final chunk');
      await this.uploadChunk(this.buffer);
      this.buffer = [];
      this.bufferSize = 0;
    }

    logger.info('>>> All chunks uploaded, starting finalization');
    await this.finalize();
    cleanup();
  };

  private handleError = (event: Event) => {
    logger.error('>>> MediaRecorder error:', event);
  };

  private handleTrackEnded = () => {
    const store = getStore();
    const { dispatch, getState } = store;
    const state = getState() as { recorder: { status: string } };
    if (state.recorder.status === 'recording') {
      dispatch(stopRecordingThunk() as any);
    }
  };

  async startRecording() {
    try {
      await mergeStreams();
      const { mergedStream, userMediaStream, displayMediaStream } = streams;
      const profile = guessProfileFromMime(this.contentType);

      this.mediaRecorder = new MediaRecorder(mergedStream, {
        mimeType: getMimeWithCodecs(profile, { audio: true, video: true }),
      });

      // Bind event handlers
      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.onstop = this.handleStop;
      this.mediaRecorder.onerror = this.handleError;

      // Add track end listeners
      [
        ...userMediaStream.getTracks(),
        ...displayMediaStream.getTracks(),
      ].forEach((track) =>
        track.addEventListener('ended', this.handleTrackEnded)
      );

      this.mediaRecorder.start(TIMESLICE);
    } catch (error) {
      logger.error('>>> Failed to start recording:', error);
      throw error;
    }
  }

  stopRecording() {
    if (
      this.mediaRecorder?.state === 'recording' ||
      this.mediaRecorder?.state === 'paused'
    ) {
      this.mediaRecorder.stop();
    }
  }

  pauseRecording() {
    if (this.mediaRecorder?.state === 'recording') {
      this.mediaRecorder.pause();
    }
  }

  resumeRecording() {
    if (this.mediaRecorder?.state === 'paused') {
      this.mediaRecorder.resume();
    }
  }
}

export default Recorder;
