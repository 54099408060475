function isDebugModeSet() {
  try {
    return window.localStorage.getItem('debug') === 'true';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return false;
  }
}

export const config = {
  appVersion: import.meta.env.VITE_APP_VERSION || '5.0.0',
  appEnv: import.meta.env.VITE_APP_ENV || 'staging',
  buildTime: import.meta.env.VITE_BUILD_TIME || '2020-01-01T00:00:00.000Z',
  version: import.meta.env.VITE_VERSION,
  commit: import.meta.env.VITE_COMMIT,
  reportErrors: import.meta.env.VITE_REPORT_ERRORS === 'true',
  adminPanelUrl:
    import.meta.env.VITE_SCREENAPP_ADMIN_DASHBOARD_DOMAIN ||
    'http://localhost:3000',
  extensionId: 'jchodkkgldoachhmohljjgmiailkbdlb',
  wsUrl: import.meta.env.VITE_WS_URL,
  trimmer: import.meta.env.VITE_TRIMMER,
  s3BucketUrl: import.meta.env.VITE_S3_BUCKET_URL,
  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '',
  stripePricingTableId: import.meta.env.VITE_STRIPE_PRICING_TABLE_ID || '',
  stripePaymentUpdateUrl: import.meta.env.VITE_STRIPE_PAYMENT_UPDATE_URL || '',
  recordPath: 'record/',
  trimmerPath: 'trim/',
  authBaseUrl: import.meta.env.VITE_AUTH_BASE_URL || 'http://localhost:8081/v1',
  authBaseUrlV2:
    import.meta.env.VITE_AUTH_BASE_URL_V2 || 'http://localhost:8081/v2',
  appBaseUrl: import.meta.env.VITE_APP_BASE_URL || 'http://localhost:8080',
  ENABLE_STREAM_RECORDER:
    import.meta.env.VITE_ENABLE_STREAM_RECORDER === 'true' || false,
  disablePlugin: import.meta.env.VITE_DISABLE_PLUGIN === 'true' || false,
  videoChunkRecovery: import.meta.env.VITE_VIDEO_CHUNK_RECOVERY !== 'false',
  tests: {
    downloadDir: '/tmp/screenapp/downloads',
    recordingTime: 10000, // Milliseconds
  },
  maxLocalRecordings: 10,
  paypalClientId: import.meta.env.VITE_PAYPAL_CLIENT_ID,
  maintenanceNotice: import.meta.env.VITE_MAINTENANCE_NOTICE,
  maintenanceNoticeEnabled:
    import.meta.env.VITE_MAINTENANCE_NOTICE_ENABLED === 'true',
  disableSubscribableTrialPlans:
    import.meta.env.VITE_DISABLE_SUBSCRIBABLE_TRIAL_PLANS !== 'false', // If null or empty, will fallback to true
  enable14DayFreeTrial: import.meta.env.VITE_ENABLE_14_DAY_FREE_TRIAL,
  jwtExpiration: 1000 * 60 * 60 * 24 * 7, // a week
  enableGen2Pricing: import.meta.env.VITE_ENABLE_GEN2_PRICING,
  intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID || '',
  permanentUnifiedFs: import.meta.env.VITE_PERMANENT_UNIFIED_FS === 'true',
  growthBookClientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  enableProductionDebugLogs:
    import.meta.env.VITE_ENABLE_PRODUCTION_DEBUG_LOGS === 'true',
  enableExternalFileImport:
    import.meta.env.VITE_ENABLE_EXTERNAL_FILE_IMPORT === 'true',
  debug:
    import.meta.env.VITE_DEBUG === 'true' || isDebugModeSet()
      ? 'screenapp:*'
      : '',
  enableCloudSync: import.meta.env.VITE_ENABLE_CLOUD_SYNC === 'true',
  enableThumbnailGeneration: import.meta.env.VITE_ENABLE_THUMBNAIL_GENERATION,
  useWebflowPricingPage:
    import.meta.env.VITE_USE_WEBFLOW_PRICING_PAGE === 'true',
  posthogApiKey: import.meta.env.VITE_POSTHOG_API_KEY || '',
  posthogApiHost:
    import.meta.env.VITE_POSTHOG_API_HOST || 'https://app.posthog.com',
  churnkeyAppId: import.meta.env.VITE_CHURNKEY_APP_ID || '',
  churunkeyMode: import.meta.env.VITE_CHURNKEY_MODE || 'test',
  geminiProxyUrl: import.meta.env.VITE_GEMINI_PROXY_URL || '',
  transcriptionEngine: import.meta.env.VITE_TRANSCRIPTION_ENGINE || 'v2',
  enableZoomIntegration:
    import.meta.env.VITE_ENABLE_ZOOM_INTEGRATION === 'true',
  enableReactScan: import.meta.env.VITE_ENABLE_REACT_SCAN === 'true',
};

export const PACKAGE_TO_PAYPAL_PLAN = {
  yearly: {
    STANDARD: [
      import.meta.env.VITE_PAYPAL_STANDARD_PLAN,
      import.meta.env.VITE_PAYPAL_STANDARD_TRIAL_PLAN,
    ],
    PREMIUM: [
      import.meta.env.VITE_PAYPAL_PREMIUM_PLAN,
      import.meta.env.VITE_PAYPAL_PREMIUM_TRIAL_PLAN,
    ],
    // Gen 2 -- no trial plans, just duplicated
    GROWTH: [
      import.meta.env.VITE_PAYPAL_GROWTH_PLAN,
      import.meta.env.VITE_PAYPAL_GROWTH_PLAN,
    ],
    BUSINESS: [
      import.meta.env.VITE_PAYPAL_BUSINESS_PLAN,
      import.meta.env.VITE_PAYPAL_BUSINESS_PLAN,
    ],
  },
  monthly: {
    STANDARD: [
      import.meta.env.VITE_PAYPAL_STANDARD_MONTHLY_PLAN,
      import.meta.env.VITE_PAYPAL_STANDARD_MONTHLY_TRIAL_PLAN,
    ],
    PREMIUM: [
      import.meta.env.VITE_PAYPAL_PREMIUM_MONTHLY_PLAN,
      import.meta.env.VITE_PAYPAL_PREMIUM_MONTHLY_TRIAL_PLAN,
    ],
    // Gen 2 -- no trial plans, just duplicated
    GROWTH: [
      import.meta.env.VITE_PAYPAL_GROWTH_MONTHLY_PLAN,
      import.meta.env.VITE_PAYPAL_GROWTH_MONTHLY_PLAN,
    ],
    BUSINESS: [
      import.meta.env.VITE_PAYPAL_BUSINESS_MONTHLY_PLAN,
      import.meta.env.VITE_PAYPAL_BUSINESS_MONTHLY_PLAN,
    ],
  },
};

export const GOOGLE_ANALYTICS_TRACIKNG_CODE = 'UA-118777783-4';
export const MEDIA_RESOLUTIONS = [
  { value: 'default', label: 'Default resolutions' },
  { value: '1920x1080', label: '1080p' },
  { value: '1280x720', label: '720p' },
  { value: '640x480', label: '480p' },
  { value: '3840x2160', label: '4K Ultra HD (3840x2160)' },
];
export const MEDIA_CONSTRANTS = {
  mediaResolutions: [
    { value: 'default', label: 'Default resolutions' },
    { value: '1920x1080', label: '1080p' },
    { value: '1280x720', label: '720p' },
    { value: '640x480', label: '480p' },
    { value: '3840x2160', label: '4K Ultra HD (3840x2160)' },
  ],
  mediaFramerates: [
    { value: 'default', label: 'Default resolutions' },
    { value: '5', label: '5 fps' },
    { value: '15', label: '15 fps' },
    { value: '24', label: '24 fps' },
    { value: '30', label: '30 fps' },
    { value: '60', label: '60 fps' },
  ],
  mediaBitrates: [
    { value: 'default', label: 'Default resolutions' },
    { value: '8000000', label: '1 MB bps' },
    { value: '800000', label: '100 KB bps' },
    { value: '8000', label: '1 KB bps' },
    { value: '800', label: '100 Bytes bps' },
  ],
};
export const GOOGLE_APP_ID =
  '388502563544-sb8ee8hc555o24aqvj1985pebnaumuoo.apps.googleusercontent.com';

export default config;
