import React, { useRef, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { streams } from '../utils/streamUtils';
import { RecorderState } from '../../store';

const CameraPreview = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { mediaStreams, settings } = useSelector(
    (state: RecorderState) => state.recorder
  );
  const { status } = mediaStreams.userMedia;
  const { webCam } = settings.videoSettings;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = streams.userMediaStream;
      videoRef.current.muted = true;
      videoRef.current.play();
    }
  }, [mediaStreams.userMedia]);

  if (!webCam) {
    return (
      <Box
        sx={{
          width: '6rem',
          height: '6rem',
        }}
      />
    );
  }

  if (status === 'pending') {
    return (
      <Skeleton
        variant="circular"
        animation="wave"
        sx={{
          width: '6rem',
          height: '6rem',
        }}
      />
    );
  }

  return (
    <Box
      position="relative"
      sx={{
        aspectRatio: '1 / 1',
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        component="video"
        ref={videoRef}
        muted
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
};

export default CameraPreview;
