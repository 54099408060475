import localforage from 'localforage';

// Will be used to store the completed recordings
export const library = localforage.createInstance({
  name: 'library',
  storeName: 'libraryStore',
});

// Store video start times and metadata for recovery purposes
export const backupRecords = localforage.createInstance({
  name: 'backupRecords',
  storeName: 'backupRecords',
});

// Store unseekable videos for backup purposes
export const rawVideos = localforage.createInstance({
  name: 'rawVideos',
  storeName: 'rawVideos',
});

// Will be used to store the chunks of the current recordings
export const chunks = localforage.createInstance({
  name: 'chunks',
  storeName: 'chunks',
});

export const folders = localforage.createInstance({
  name: 'folders',
  storeName: 'folders',
});

export const fileSystem = localforage.createInstance({
  name: 'fileSystem',
});
