import React, { useEffect, useRef, useState } from 'react';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import { Mic } from 'lucide-mui';
import { streams } from '../utils/streamUtils';

const FFT_SIZE = 128;
const MAX_RADIUS = 1.5;

const MicIconWithVisualization: React.FC<SvgIconProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [hasAudioInput, setHasAudioInput] = useState(false);

  useEffect(() => {
    const { userMediaStream } = streams;
    const audioTrack = userMediaStream?.getAudioTracks()[0];
    setHasAudioInput(!!audioTrack);
    if (!audioTrack) return;

    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(
      new MediaStream([audioTrack])
    );
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = FFT_SIZE;
    source.connect(analyser);

    let animationFrameId: number;

    const draw = (): void => {
      if (!canvasRef.current) return;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      for (let i = 0; i < dataArray.length; i += 1) {
        const value = dataArray[i];
        const radius = (value / 255) * MAX_RADIUS * 20;
        const x =
          centerX + radius * Math.cos((i * 2 * Math.PI) / dataArray.length);
        const y =
          centerY + radius * Math.sin((i * 2 * Math.PI) / dataArray.length);
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(x, y);
        ctx.strokeStyle = `rgba(25, 118, 210, ${value / 255})`;
        ctx.stroke();
      }

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    // eslint-disable-next-line consistent-return
    return () => {
      cancelAnimationFrame(animationFrameId);
      audioContext.close();
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '2.75rem',
        height: '2.75rem',
      }}
    >
      <canvas
        ref={canvasRef}
        width={400}
        height={400}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      />
      <Mic
        {...props}
        style={{
          position: 'relative',
          zIndex: 1,
          animation: !hasAudioInput
            ? 'pulse 1.5s ease-in-out infinite'
            : 'none',
        }}
      />
      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); opacity: 1; }
            50% { transform: scale(1.1); opacity: 0.7; }
            100% { transform: scale(1); opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default MicIconWithVisualization;
