import { configureStore } from '@reduxjs/toolkit';
import type { AnyAction, Dispatch } from 'redux';
import type { Middleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import recorderReducer from './recorderSlice';

const reducer = {
  recorder: recorderReducer,
};

let store: ReturnType<typeof configureStore>;

export const initStore = (
  middlewareArr: Middleware<{}, any, Dispatch<AnyAction>>[]
) => {
  // If we directly assign the return value to store, types are not inferred correctly
  const _store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        createLogger({
          diff: true,
        }) as Middleware<{}, any, Dispatch<AnyAction>>, // Ensure the logger middleware matches the expected type
        ...middlewareArr
      ),
  });
  store = _store;
  return _store;
};

export const getStore = () => {
  if (!store) {
    throw new Error('Store not ready');
  }
  return store;
};

export type StoreType = ReturnType<typeof initStore>;
export type RecorderState = ReturnType<StoreType['getState']>;
export type AppDispatch = StoreType['dispatch'];

export type RootState = RecorderState;
