import { finalizeUpload } from './thunk';

interface InitializeEventDetail {
  teamId: string;
  folderId: string;
}

interface FinalizedEventDetail {
  teamId: string;
  folderId: string;
  file: ReturnType<typeof finalizeUpload.fulfilled>['payload'];
}
type EventMap = {
  ready: CustomEvent<InitializeEventDetail>;
  stopped: CustomEvent<{ folderId: string; fileId: string; progress: number }>;
  uploaded: CustomEvent<FinalizedEventDetail>;
  error: CustomEvent<{ message: string }>;
  canceled: CustomEvent<{}>;
};

type EventListener<K extends keyof EventMap> = (event: EventMap[K]) => void;

class EventEmitter {
  private target: EventTarget;

  constructor() {
    this.target = new EventTarget();
  }

  emit<K extends keyof EventMap>(type: K, detail: EventMap[K]['detail']) {
    const event = new CustomEvent(type, { detail });
    this.target.dispatchEvent(event);
  }

  on<K extends keyof EventMap>(type: K, listener: EventListener<K>) {
    this.target.addEventListener(type, listener);
  }

  off<K extends keyof EventMap>(type: K, listener: EventListener<K>) {
    this.target.removeEventListener(type, listener);
  }
}

export { EventEmitter, EventMap, EventListener, InitializeEventDetail };
