import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Backdrop, Box, useTheme, Collapse, IconButton } from '@mui/material';
import { Minimize2 } from 'lucide-mui';
import DeviceSelection from './deviceSelection';
import RecorderControls from './RecorderControls';
import Notepad from './Notepad';
import { RootState } from '../store';
import { NOTEPAD_VISIBLE_KEY } from '../../constants/tags';
import PopupRecorder from './PopupRecorder';
import { setUIMode } from '../recorderSlice';

const RecorderComponent = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { status, controls } = useSelector((state: RootState) => state.recorder);
  const [showNotes, setShowNotes] = useState(() => {
    const savedValue = localStorage.getItem(NOTEPAD_VISIBLE_KEY);
    return savedValue ? JSON.parse(savedValue) : true;
  });
  const isBackdropInvisible = status === 'stopped';
  const showDeviceSelection = status === 'initializing' || status === 'ready';
  const isSaving = status === 'saving';
  const { uiMode, isMountedFromPlugin } = controls;

  const handleToggleNotes = () => {
    setShowNotes((prev: boolean) => {
      const newValue = !prev;
      localStorage.setItem(NOTEPAD_VISIBLE_KEY, JSON.stringify(newValue));
      return newValue;
    });
  };

  const handleSwitchToPopup = () => {
    dispatch(setUIMode('popup'));
  };

  if (showDeviceSelection) {
    return <DeviceSelection />;
  }

  // Use the popup UI mode if selected
  if (uiMode === 'popup' && !showDeviceSelection) {
    return <PopupRecorder />;
  }

  const backdropColor =
    theme.palette.mode === 'dark' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)';

  return (
    <Backdrop
      open
      invisible={isBackdropInvisible && !isSaving}
      sx={{
        backgroundColor: backdropColor,
        zIndex: theme.zIndex.drawer + 1,
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          opacity: isBackdropInvisible && !isSaving ? 0 : 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '600px',
          height: '100%',
          p: 2,
        }}
      >
        {/* Minimize button - only shown when mounted from plugin */}
        {isMountedFromPlugin && (
          <Box sx={{ 
            position: 'absolute', 
            top: 10, 
            right: 10, 
            zIndex: 1 
          }}>
            <IconButton
              size="small"
              onClick={handleSwitchToPopup}
              sx={{
                bgcolor: 'rgba(0, 0, 0, 0.1)',
                color: theme.palette.text.primary,
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <Minimize2 fontSize="small" />
            </IconButton>
          </Box>
        )}
        
        <RecorderControls
          onToggleNotes={handleToggleNotes}
          showNotes={showNotes}
        />
        <Collapse
          in={showNotes}
          timeout={200}
          sx={{
            width: '100%',
            flex: showNotes ? 1 : 0,
            display: 'flex',
            overflow: 'hidden',
            transition: theme.transitions.create(['flex'], {
              duration: theme.transitions.duration.shorter,
            }),
            '& .MuiCollapse-wrapperInner': {
              display: 'flex',
              flex: 1,
            },
          }}
        >
          <Box sx={{ flex: 1, minHeight: 0 }}>
            <Notepad />
          </Box>
        </Collapse>
      </Box>
    </Backdrop>
  );
};

export default RecorderComponent;
