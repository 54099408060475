import { createTheme } from '@mui/material';
import type { Theme as MaterialUITheme } from '@mui/material';
import Grow from '@mui/material/Grow';
import {
  sharedStyleOverrides,
  sharedSvgIconStyles,
  sharedTypography,
  brandColors,
} from './shared';

// Define colors
const colors = {
  black: '#000000', // Pure black, used for common black color
  white: '#FFFFFF', // Pure white, used for common white color
  primaryMain: brandColors.primaryMain,
  primaryLight: brandColors.primaryLight,
  primaryDark: brandColors.primaryDark,
  secondaryMain: '#E5E7EB', // Secondary main color - very light gray
  secondaryLight: '#F3F4F6', // Secondary light color - light gray
  secondaryDark: '#D1D5DB', // Secondary dark color - medium gray
  secondaryContrastText: '#374151', // Secondary contrast text color - dark gray
  warningMain: '#F79804DE', // Warning color - orange
  infoMain: '#3696F3', // Info color - blue
  errorMain: '#F44336', // Error color - red
  successMain: '#4CAF51', // Success color - green
  textPrimary: brandColors.textDark, // Primary text color - dark gray
  hoverColor: brandColors.interactiveLight, // Changed from primary to neutral color
  dividerColor: '#F3F4F6', // Divider color - very light gray
  backgroundDefault: '#F9FAFB', // Background default color - light gray
  backgroundPaper: '#FFFFFF', // Background paper color - white
  skeletonColor: '#f3f4f6', // Skeleton color - darker blue of the same shade as default bg
  actionHover: '#f9fafb', // Light gray hover background
};

const palette = {
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: {
    main: colors.primaryMain,
    light: colors.primaryLight,
    dark: colors.primaryDark,
    contrastText: colors.white,
  },
  background: {
    default: colors.backgroundDefault,
    paper: colors.backgroundPaper,
  },
  secondary: {
    main: colors.secondaryMain,
    light: colors.secondaryLight,
    dark: colors.secondaryDark,
    contrastText: colors.secondaryContrastText,
  },
  warning: {
    main: colors.warningMain,
    contrastText: colors.white,
  },
  info: {
    main: colors.infoMain,
    contrastText: colors.textPrimary,
  },
  error: {
    main: colors.errorMain,
    contrastText: colors.textPrimary,
  },
  success: {
    main: colors.successMain,
    contrastText: colors.textPrimary,
  },
  text: {
    primary: colors.textPrimary,
  },
  divider: colors.dividerColor,
  action: {
    hover: colors.actionHover,
  },
};

export const theme = createTheme({
  palette,
  typography: sharedTypography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...sharedStyleOverrides,
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: colors.secondaryMain,
          borderRadius: '4px',
        },
        '*': {
          ...sharedStyleOverrides['*'],
          scrollbarColor: `${colors.secondaryMain} transparent`,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          ...sharedSvgIconStyles,
          color: '#363739',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '9999px', // Fully rounded
          '&.MuiButton-outlinedSecondary': {
            border: `1px solid ${colors.secondaryMain}`,
            borderColor: colors.secondaryMain,
            color: colors.secondaryContrastText,
            boxShadow: 'none',
            backgroundColor: colors.backgroundPaper,
            '&:hover': {
              border: `1px solid ${colors.secondaryDark}`,
              borderColor: colors.secondaryDark,
              boxShadow: 'none',
              backgroundColor: colors.backgroundDefault,
              '& .MuiSvgIcon-root': {
                color: brandColors.interactiveLight,
              },
            },
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
          '&.MuiButton-text': {
            color: brandColors.textDark, // Use textDark instead of primaryText
          },
        },
        containedPrimary: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.primaryDark,
            '& .MuiSvgIcon-root': {
              color: colors.white,
            },
          },
          '& .MuiSvgIcon-root': {
            color: colors.white,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
        containedSecondary: {
          backgroundColor: colors.white,
          boxShadow: 'none',
          color: colors.secondaryContrastText,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.secondaryLight,
            '& .MuiSvgIcon-root': {
              color: brandColors.interactiveLight,
            },
          },
          '& .MuiSvgIcon-root': {
            color: colors.secondaryContrastText,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
        containedError: {
          boxShadow: 'none',
          backgroundColor: '#d32f2f', // Darker red color
          color: colors.white,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#b71c1c', // Even darker red on hover
          },
          '& .MuiSvgIcon-root': {
            color: colors.white,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
        containedSuccess: {
          boxShadow: 'none',
          color: '#fff',
          fontWeight: 600,
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          '&.MuiListItemButton-gutters': {
            paddingLeft: 8 * 1.5,
            paddingRight: 0,
          },
          '& .MuiListItemIcon-root': {
            marginRight: '12px',
          },
          '&:hover .MuiSvgIcon-root': {
            color: brandColors.interactiveLight, // Use neutral hover color
          },
          '&:hover': {
            backgroundColor: colors.secondaryLight,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '1.5rem',
          height: '1.5rem',
          backgroundColor: colors.secondaryMain,
          '& .MuiSvgIcon-root': {
            color: colors.white, // SVG color for avatar - white
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-outlinedSecondary': {
            bgcolor: colors.backgroundPaper,
            color: colors.textPrimary,
            '&:hover': {
              bgcolor: colors.backgroundDefault,
              boxShadow: `0 1px 3px ${colors.secondaryLight}, 0 1px 2px ${colors.secondaryLight}`,
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.dividerColor, // Divider color - very light gray
          backgroundColor: colors.dividerColor, // Background color for divider - very light gray
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          '&.MuiAlert-standardSuccess': {
            '& .MuiAlert-icon': {
              color: colors.successMain,
            },
          },
          '&.MuiAlert-standardError': {
            '& .MuiAlert-icon': {
              color: colors.errorMain,
            },
          },
          '&.MuiAlert-standardWarning': {
            '& .MuiAlert-icon': {
              color: colors.warningMain,
            },
          },
          '&.MuiAlert-standardInfo': {
            '& .MuiAlert-icon': {
              color: colors.infoMain,
            },
          },
        },
        icon: {
          display: 'flex',
          alignItems: 'center',
          marginRight: '12px',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        TransitionComponent: Grow,
        TransitionProps: {
          timeout: 300,
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${colors.primaryMain}`, // Hover border color for inputs - primary main color
          },
          '&:hover .MuiSvgIcon-root': {
            color: colors.hoverColor, // Hover color for icons in inputs - dark gray
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:hover .MuiSvgIcon-root': {
            color: brandColors.interactiveLight, // Use neutral hover color
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${colors.secondaryLight}`, // Border color - light gray
          borderRadius: '8px', // Rounded corners
          boxShadow: '0px 2px 6px rgba(16, 24, 40, 0.03)', // Shadow for popover
          backgroundColor: colors.backgroundPaper, // Background color for popover - white
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            padding: '0',
          },
          '& .MuiAutocomplete-input': {
            padding: 0,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.skeletonColor, // Skeleton color - darker blue of the same shade as default bg
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 8,
          backgroundColor: colors.secondaryLight, // Unfilled progress background color
        },
        bar: {
          borderRadius: 4,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.textPrimary,
          '&:hover': {
            color: brandColors.interactiveLight,
          },
        },
      },
    },
  },
});

export type Theme = MaterialUITheme;
export default theme;
