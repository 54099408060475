import type { Middleware } from 'redux';
import { cancelThunk, stopRecordingThunk, finalizeUpload } from './thunk'; // Adjust the import path accordingly
import { EventEmitter } from './EventEmitter';
import type { StoreType } from './store';

const getMiddleware = (eventEmitter: EventEmitter) => {
  const middleware: Middleware = (store: StoreType) => (next) => (action) => {
    const { teamId, folderId, fileId } = store.getState().recorder.upload;
    switch (action.type) {
      case cancelThunk.pending.type:
        eventEmitter.emit('canceled', {});
        break;
      case stopRecordingThunk.pending.type:
        if (folderId && fileId) {
          eventEmitter.emit('stopped', {
            folderId,
            fileId,
            progress: 100,
          });
        }
        break;
      case finalizeUpload.fulfilled.type:
        if (teamId && folderId) {
          eventEmitter.emit('uploaded', {
            teamId,
            folderId,
            file: action.payload,
          });
        }
        break;
      default:
        break;
    }

    return next(action);
  };
  return middleware;
};

export default getMiddleware;
