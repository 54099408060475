import axios from 'axios';
import moment from 'moment';
import { ContentType } from '../constants/mediaFormatProfiles';
import { IVFSResponse } from '../services/fileSystemService';
import { apiV2 } from '../lib/baseAxios';
import { FileType, TimeStampedLine } from '../types';
import logger from '../lib/logger';

interface InitializeMultipartUploadOptions {
  teamId: string;
  folderId: string;
  contentType: ContentType;
}
interface InitializeMultipartUploadResponse {
  fileId: string;
  uploadId: string;
}
export const initializeMultipartUpload = async ({
  teamId,
  folderId,
  contentType,
}: InitializeMultipartUploadOptions) => {
  // /:teamId/:folderId/upload/multipart
  const response = await apiV2.put<
    IVFSResponse<InitializeMultipartUploadResponse>
  >(`/files/upload/multipart/init/${teamId}/${folderId}`, {
    contentType,
  });
  return response.data.data;
};

interface CreatePartUploadUrl {
  teamId: string;
  folderId: string;
  fileId: string;
  uploadId: string;
  partNumber: number;
  contentType: ContentType;
}

interface PartUploadUrlResponse {
  uploadUrl: string;
}

export const createPartUploadUrl = async ({
  teamId,
  folderId,
  fileId,
  uploadId,
  partNumber,
  contentType,
}: CreatePartUploadUrl) => {
  // /:teamId/:folderId/upload/multipart/part
  const response = await apiV2.put<IVFSResponse<PartUploadUrlResponse>>(
    `/files/upload/multipart/url/${teamId}/${folderId}/${fileId}/${uploadId}/${partNumber}`,
    {
      contentType,
    }
  );
  console.log('response', response);
  console.log('response.data', response.data);
  return response.data.data.uploadUrl;
};

type FinalizeUploadOptions = {
  teamId: string;
  folderId?: string;
  fileId: string;
  uploadId: string;
  contentType: ContentType;
  notes: TimeStampedLine[];
  title?: string;
};
interface FinalizeUploadResponseBody {
  file: FileType;
}

export const finalizeUpload = async ({
  teamId,
  folderId,
  fileId,
  uploadId,
  contentType,
  notes,
  title,
}: FinalizeUploadOptions) => {
  // /:teamId/:folderId/upload/multipart
  const response = await apiV2.put<IVFSResponse<FinalizeUploadResponseBody>>(
    `/files/upload/multipart/finalize/${teamId}/${folderId}/${fileId}/${uploadId}`,
    {
      file: {
        contentType,
        name:
          title?.trim() ||
          `New Recording ${moment().format('h:mma MMM DD YYYY')}`,
        notes,
      },
    }
  );
  return response.data.data.file;
};

export const uploadChunkToS3 = async ({
  uploadUrl,
  chunk,
}: {
  uploadUrl: string;
  chunk: Blob;
}) => {
  if (!uploadUrl) {
    throw new Error('No upload URL provided');
  }
  try {
    await axios.put(uploadUrl, chunk, {
      headers: {
        'Content-Type': chunk.type,
      },
    });
  } catch (error) {
    logger.error('Error uploading chunk to S3', error);
    throw error;
  }
};
