import { backupRecords, chunks } from './baseStorage';

// Add constants at the top of the file
const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;
const DAYS_FOR_CLEANUP = 14;
const DEFAULT_BATCH_SIZE = 10;

export interface BackupRecord {
  teamId?: string;
  folderId?: string;
  fileId: string;
  uploadId?: string;
  startedAt: number;
}

export const addBackupRecord = async (fileId: string, value: BackupRecord) => {
  await backupRecords.setItem(fileId, value);
};

export const getBackupRecords = async (teamId?: string) => {
  const rs = await (Promise.all(
    (await backupRecords.keys()).map((key) => backupRecords.getItem(key))
  ) as Promise<BackupRecord[]>);

  if (teamId) {
    return rs.filter((r) => r.teamId === teamId);
  }

  return rs;
};

export const deleteBackupRecord = async (fileId: string) => {
  await backupRecords.removeItem(fileId);
};

const makeChunkKey = (fileId: string, partNumber: number) => {
  return `${fileId}_${partNumber}`;
};

export const saveChunk = async (
  fileId: string,
  partNumber: number,
  value: any
) => {
  await chunks.setItem(makeChunkKey(fileId, partNumber), value);
};

export const loadChunk = async (fileId: string, partNumber: number) => {
  return chunks.getItem(makeChunkKey(fileId, partNumber)) as Promise<Blob>;
};

export const listChunks = async (fileId?: string) => {
  const keys = await chunks.keys();

  if (fileId) {
    return keys.filter((key) => key.startsWith(fileId));
  }

  return keys;
};

export const deleteChunks = async (fileId: string) => {
  const keys = await listChunks(fileId);
  await Promise.all(
    keys.map(async (key) => {
      await chunks.removeItem(key);
    })
  );
};

/**
 * Cleanup old backups without specifying the team. Delete backups older than specified days.
 * @param batchSize - Number of records to delete in each batch (default 5)
 * @param daysOld - Number of days after which backups are considered old (default 14)
 * @returns Number of records deleted
 */
export const cleanupOldBackups = async (
  batchSize = DEFAULT_BATCH_SIZE,
  daysOld = DAYS_FOR_CLEANUP
) => {
  try {
    const allRecords = await backupRecords
      .keys()
      .then((keys) =>
        Promise.all(
          keys.map((key) => backupRecords.getItem(key) as Promise<BackupRecord>)
        )
      );

    const cutoffDate = Date.now() - MILLISECONDS_IN_DAY * daysOld;
    const oldRecords = allRecords
      .filter((record) => record.startedAt < cutoffDate)
      .sort((a, b) => a.startedAt - b.startedAt); // Sort oldest first

    // Process only a batch of oldest records
    const toDelete = oldRecords.slice(0, batchSize);

    await Promise.all(
      toDelete.map(async (record) => {
        console.log('Deleting backup record', record);
        await deleteBackupRecord(record.fileId);
        await deleteChunks(record.fileId);
      })
    );

    return toDelete.length; // Return number of deleted records
  } catch (error) {
    console.error('Backup cleanup failed:', error);
    return 0;
  }
};
