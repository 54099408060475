import React from 'react';
import {
  ButtonGroup,
  Button,
  Typography,
  useTheme,
  Box,
  useMediaQuery,
  Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Timer from './Timer';
import type { RecorderState } from '../../store';
import AudioWaveform from './AudioWaveform';
import ControlButtons from './ControlButtons';
import Loader from '../../../features/app/loader/LoaderSvg';
import CameraPreview from '../MediaPreview/Camera';
import Display from '../MediaPreview/Display';

interface RecorderControlsProps {
  onToggleNotes: () => void;
  showNotes: boolean;
  isPopupMode?: boolean;
}

const RecorderControls: React.FC<RecorderControlsProps> = ({
  onToggleNotes,
  showNotes,
  isPopupMode = false,
}) => {
  const theme = useTheme();
  const { status } = useSelector((state: RecorderState) => state.recorder);
  const isRecording = status === 'recording';
  const isPaused = status === 'paused';
  const isStopped = status === 'stopped';
  const isSaving = status === 'saving';
  const showTimer = isRecording || isPaused;
  const showControls = isRecording || isPaused;
  const showLoader = isSaving;

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // In popup mode, we don't show the camera preview
  const showCameraPreview =
    (status === 'recording' || status === 'paused') && !isSmall && !isPopupMode;
  
  // In popup mode, we show a smaller display preview
  const showDisplayPreview = (status === 'recording' || status === 'paused') && !isPopupMode;

  return (
    <Grid
      container
      direction="column"
      sx={{
        bgcolor:
          theme.palette.mode === 'dark' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)',
        p: isPopupMode ? 0 : 1,
        mb: isPopupMode ? 0 : 2,
        ...(showLoader && {
          height: isPopupMode ? 'auto' : '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }),
      }}
    >
      {!showLoader ? (
        <>
          {showDisplayPreview && (
            <Grid item container sx={{ position: 'relative', mb: 2 }}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <Display />
                {showCameraPreview && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: theme.spacing(2),
                      left: theme.spacing(2),
                      width: '6rem',
                      height: '6rem',
                      zIndex: 1,
                    }}
                  >
                    <CameraPreview />
                  </Box>
                )}
              </Grid>
            </Grid>
          )}

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            sx={{ height: isPopupMode ? 'auto' : '3.75rem' }}
          >
            <Grid item sx={{ flex: 1 }}>
              <ButtonGroup sx={{ height: isPopupMode ? '2.5rem' : '3.75rem', width: '100%' }}>
                <Button
                  color="secondary"
                  variant="text"
                  sx={{
                    width: '100%',
                    height: '100%',
                    bgcolor:
                      theme.palette.mode === 'dark'
                        ? 'rgb(0, 0, 0)'
                        : 'rgb(255, 255, 255)',
                    '&:hover': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? 'rgb(0, 0, 0)'
                          : 'rgb(255, 255, 255)',
                    },
                  }}
                >
                  <Box
                    sx={{ position: 'relative', height: '100%', width: '100%' }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        width: '100%',
                        opacity: isRecording ? 1 : 0,
                      }}
                    >
                      <AudioWaveform />
                    </Box>
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor:
                          theme.palette.mode === 'dark'
                            ? 'rgba(0, 0, 0, 0.8)'
                            : 'rgba(255, 255, 255, 0.8)',
                        color: theme.palette.text.primary,
                        fontSize: isPopupMode ? '0.8rem' : '1rem',
                        opacity: isPaused || isStopped ? 1 : 0,
                      }}
                    >
                      {isPaused ? 'Paused' : 'Saving...'}
                    </Typography>
                  </Box>
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid
              item
              sx={{
                opacity: isStopped ? 0 : 1,
                transition: 'opacity 0.3s ease-in-out',
                visibility: isStopped ? 'hidden' : 'visible',
              }}
            >
              {showTimer && (
                <Typography
                  sx={{
                    color: theme.palette.secondary.contrastText,
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                    fontSize: isPopupMode ? '0.8rem' : '1rem',
                  }}
                >
                  <Timer />
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                opacity: isStopped ? 0 : 1,
                transition: 'opacity 0.3s ease-in-out',
                visibility: isStopped ? 'hidden' : 'visible',
              }}
            >
              {showControls && (
                <ControlButtons
                  onToggleNotes={isPopupMode ? () => {} : onToggleNotes}
                  showNotes={isPopupMode ? false : showNotes}
                  isPopupMode={isPopupMode}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item>
          <Box sx={{ width: isPopupMode ? '2.5rem' : '3.75rem', height: isPopupMode ? '2.5rem' : '3.75rem' }}>
            <Loader animate />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RecorderControls;
