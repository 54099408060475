import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { RootState } from '../../store';
import { incrementTimer } from '../../recorderSlice';

const Timer = () => {
  const dispatch = useDispatch();
  const timer = useSelector((state: RootState) => state.recorder.timer);
  const status = useSelector((state: RootState) => state.recorder.status);

  useEffect(() => {
    let timerInterval: ReturnType<typeof setInterval>;
    if (status === 'recording') {
      timerInterval = setInterval(() => {
        dispatch(incrementTimer());
      }, 1000);
    }
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [status, dispatch]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  return <Typography>{formatTime(timer)}</Typography>;
};

export default Timer;
