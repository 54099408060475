import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  Typography,
  Box,
  Button,
  useTheme,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Alert,
  Dialog,
  IconButton,
  Grid,
  Divider,
  Menu,
  Collapse,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppWindow,
  AppWindowMac,
  Monitor,
  MonitorX,
  Video,
  VideoOff,
  MicOff,
  Mic,
  Disc,
  X,
  ChevronDown,
} from 'lucide-mui';
import { updateSelectedDevices, setDisplaySurface } from '../../recorderSlice';
import type { DisplaySurface } from '../../recorderSlice';
import {
  fetchAvailableDevices,
  fetchUserMediaThunk,
  fetchDisplayMediaThunk,
  cancelThunk,
  initializeUploadThunk,
  startRecordingThunk,
  createBackupRecordThunk,
} from '../../thunk';
import type { RecorderState } from '../../store';
import logger from '../../../lib/logger';
import MicIconWithVisualization from './MicIconWithAudioVisualizer';

const displaySurfaceOptions: Record<
  DisplaySurface,
  { label: string; icon: React.ReactNode }
> = {
  browser: {
    label: 'Browser tab',
    icon: <AppWindow sx={{ fontSize: '2.5rem' }} />,
  },
  window: {
    label: 'Window',
    icon: <AppWindowMac sx={{ fontSize: '2.5rem' }} />,
  },
  monitor: {
    label: 'Entire Screen',
    icon: <Monitor sx={{ fontSize: '2.5rem' }} />,
  },
  none: {
    label: "Don't share screen",
    icon: <MonitorX sx={{ fontSize: '2.5rem' }} />,
  },
};

// Common styles
const commonBoxStyles = (theme: any, isActive: boolean) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2.5),
  borderRadius: '16px',
  cursor: 'pointer',
  backgroundColor: isActive
    ? theme.palette.secondary.main
    : theme.palette.background.paper,
  color: isActive
    ? theme.palette.secondary.contrastText
    : theme.palette.text.primary,
  border: `1px solid ${
    isActive ? theme.palette.secondary.main : theme.palette.divider
  }`,
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    backgroundColor: isActive
      ? theme.palette.secondary.dark
      : theme.palette.action.hover,
    borderColor: isActive
      ? theme.palette.secondary.dark
      : theme.palette.primary.main,
    color: isActive
      ? theme.palette.secondary.contrastText
      : theme.palette.text.primary,
  },
  gap: 2,
  justifyContent: 'space-between',
});

const commonTypographyStyles = (webCam?: boolean) => ({
  fontWeight: 500,
  textAlign: 'center',
  fontSize: '0.875rem',
  lineHeight: 1.3,
  maxWidth: webCam ? '140px' : '280px',
});

const DeviceSelection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallViewport = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    status: recorderStatus,
    selectedDevices,
    availableDevices,
    mediaStreams,
    settings,
  } = useSelector((state: RecorderState) => state.recorder);
  const { status: userMediaStatus } = mediaStreams.userMedia;
  const { videoinput, audioinput, displaySurface } = selectedDevices;
  const { webCam, screen } = settings?.videoSettings || {};
  const { microphoneAudio } = settings?.audioSettings || {};
  const [showAudioWarning, setShowAudioWarning] = useState(false);
  const [micAnchorEl, setMicAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const handleDeviceChange = () => {
      console.log('Device change detected');
      dispatch(
        fetchAvailableDevices({ camera: webCam, microphone: microphoneAudio })
      );
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        dispatch(
          fetchAvailableDevices({ camera: webCam, microphone: microphoneAudio })
        );
      }
    };

    // Initial fetch
    dispatch(
      fetchAvailableDevices({ camera: webCam, microphone: microphoneAudio })
    );

    // Setup event listeners
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      );
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [dispatch]);

  useEffect(() => {
    if (availableDevices.status === 'succeeded') {
      dispatch(
        fetchUserMediaThunk({
          video:
            videoinput.deviceId !== 'none'
              ? { deviceId: videoinput.deviceId }
              : false,
          audio:
            audioinput.deviceId !== 'none'
              ? { deviceId: audioinput.deviceId }
              : false,
        })
      );
    }
  }, [availableDevices]);

  useEffect(() => {
    setShowAudioWarning(displaySurface !== 'browser');
  }, [displaySurface]);

  // Helper function to update localStorage
  const updateRecorderSettings = (
    kind: 'audio' | 'video',
    deviceId: string
  ) => {
    const currentSettings = JSON.parse(
      localStorage.getItem('recorderSettings') || '{}'
    );
    const updatedSettings = {
      ...currentSettings,
      selectedDevices: {
        ...currentSettings.selectedDevices,
        [kind === 'audio' ? 'audioinput' : 'videoinput']: deviceId,
      },
    };
    localStorage.setItem('recorderSettings', JSON.stringify(updatedSettings));
  };

  const getHandleDeviceChange = (kind: 'audio' | 'video') => (
    deviceId: string
  ) => {
    dispatch(
      fetchUserMediaThunk({
        [kind]: deviceId === 'none' ? false : { deviceId },
      })
    );
    updateRecorderSettings(kind, deviceId);
  };

  // Helper function to update localStorage
  const onChangeDisplaySurface = (value: DisplaySurface) => {
    dispatch(setDisplaySurface(value));
    const currentSettings = JSON.parse(
      localStorage.getItem('recorderSettings') || '{}'
    );
    const updatedSettings = {
      ...currentSettings,
      selectedDevices: {
        ...currentSettings.selectedDevices,
        displaySurface: value,
      },
    };
    localStorage.setItem('recorderSettings', JSON.stringify(updatedSettings));
  };

  const handleStart = async () => {
    if (displaySurface !== 'none') {
      const fetchDisplayMediaResult = await dispatch(
        fetchDisplayMediaThunk({
          video: {
            // @ts-ignore
            displaySurface,
          },
          audio: true,
        })
      );
      if (
        // @ts-ignore - TS doesn't know about the type of the action
        fetchDisplayMediaResult.type === fetchDisplayMediaThunk.rejected.type
      ) {
        logger.log('Error fetching display media');
        dispatch(cancelThunk());
        return;
      }
    }
    if (
      !(
        displaySurface === 'none' &&
        videoinput.deviceId === 'none' &&
        audioinput.deviceId === 'none'
      )
    ) {
      await dispatch(initializeUploadThunk());
      await dispatch(startRecordingThunk());
      await dispatch(createBackupRecordThunk());
    }
  };

  if (recorderStatus !== 'ready') {
    return null;
  }

  if (availableDevices.status === 'pending') {
    return <Typography>Loading devices...</Typography>;
  }

  if (availableDevices.status === 'failed') {
    return (
      <Typography>Error loading devices: {availableDevices.error}</Typography>
    );
  }

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth={isSmallViewport}
      onClose={() => dispatch(cancelThunk())}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          width: isSmallViewport ? '100%' : '32rem',
          margin: isSmallViewport ? 2 : undefined,
          position: 'relative',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => dispatch(cancelThunk())}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: theme.palette.text.secondary,
          transition:
            'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.1)',
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        <X />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          padding: theme.spacing(4, 3, 3),
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          align="center"
          sx={{
            mb: 2,
            fontWeight: 500,
            color: theme.palette.text.primary,
          }}
        >
          Choose what to record
        </Typography>
        {screen && (
          <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
              {Object.entries(displaySurfaceOptions).map(
                ([key, { label, icon }]) => (
                  <Grid item xs={3} key={key}>
                    <Box
                      onClick={() =>
                        onChangeDisplaySurface(key as DisplaySurface)
                      }
                      sx={{
                        ...commonBoxStyles(theme, displaySurface === key),
                        padding: theme.spacing(1.5),
                        gap: 1.5,
                        minHeight: '100px',
                      }}
                    >
                      {icon}
                      <Typography
                        variant="caption"
                        sx={{
                          ...commonTypographyStyles(),
                          fontSize: '0.75rem',
                        }}
                      >
                        {label}
                      </Typography>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
            <Collapse in={showAudioWarning}>
              <Alert
                severity="info"
                sx={{
                  fontSize: '0.875rem',
                  mt: 2,
                  borderRadius: '8px',
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(66, 107, 255, 0.1)'
                      : undefined,
                  '& .MuiAlert-icon': { color: '#426BFF' },
                  '& .MuiSvgIcon-root': { color: '#426BFF' },
                }}
              >
                Browser audio will not be captured. Share a browser tab to
                record browser audio.
              </Alert>
            </Collapse>
          </Box>
        )}

        {screen && (microphoneAudio || webCam) && (
          <Divider sx={{ mt: 0.5, mb: 1 }} />
        )}

        <Grid container spacing={3}>
          {microphoneAudio && (
            <Grid item xs={webCam ? 6 : 12}>
              <Box
                onClick={(event) => {
                  if (availableDevices.audioinput.length > 1) {
                    setMicAnchorEl(event.currentTarget);
                  } else {
                    const nextDeviceId =
                      audioinput.deviceId === 'none'
                        ? availableDevices.audioinput[0]?.deviceId || 'none'
                        : 'none';
                    getHandleDeviceChange('audio')(nextDeviceId);
                    dispatch(
                      updateSelectedDevices({ audioinput: nextDeviceId })
                    );
                  }
                }}
                sx={{
                  ...commonBoxStyles(theme, audioinput.deviceId !== 'none'),
                  minHeight: '130px',
                  position: 'relative',
                }}
              >
                {audioinput.deviceId === 'none' ? (
                  <MicOff sx={{ fontSize: '2.75rem' }} />
                ) : (
                  <MicIconWithVisualization sx={{ fontSize: '2.75rem' }} />
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
                  <Typography
                    variant="caption"
                    noWrap
                    sx={commonTypographyStyles(webCam)}
                  >
                    {audioinput.deviceId === 'none'
                      ? 'Microphone Off'
                      : availableDevices.audioinput.find(
                          (d) => d.deviceId === audioinput.deviceId
                        )?.label || 'Microphone'}
                  </Typography>
                  {availableDevices.audioinput.length > 1 && (
                    <ChevronDown sx={{ fontSize: '1.25rem', flexShrink: 0 }} />
                  )}
                </Box>
              </Box>
              <Menu
                anchorEl={micAnchorEl}
                open={Boolean(micAnchorEl)}
                onClose={() => setMicAnchorEl(null)}
                PaperProps={{
                  sx: {
                    minWidth: micAnchorEl?.offsetWidth,
                    maxWidth: '300px',
                    maxHeight: '300px',
                    mt: 1,
                    borderRadius: '8px',
                    backgroundColor: theme.palette.background.paper,
                    '& .MuiMenuItem-root': {
                      py: 1.5,
                      px: 2,
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                        transform: 'translateX(4px)',
                      },
                    },
                    '& .MuiListItemText-root': {
                      flex: '1 1 auto',
                      minWidth: 0,
                    },
                    '& .MuiListItemText-secondary': {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: theme.palette.text.secondary,
                    },
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                MenuListProps={{
                  sx: {
                    width: '100%',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    getHandleDeviceChange('audio')('none');
                    dispatch(updateSelectedDevices({ audioinput: 'none' }));
                    setMicAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <MicOff sx={{ color: theme.palette.text.primary }} />
                  </ListItemIcon>
                  <ListItemText secondary="Don't use microphone" />
                </MenuItem>
                {availableDevices.audioinput.map((device) => (
                  <MenuItem
                    key={device.deviceId}
                    onClick={() => {
                      getHandleDeviceChange('audio')(device.deviceId);
                      dispatch(
                        updateSelectedDevices({ audioinput: device.deviceId })
                      );
                      setMicAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <Mic sx={{ color: theme.palette.text.primary }} />
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        device.label || `Microphone ${device.deviceId}`
                      }
                    />
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}

          {webCam && (
            <Grid item xs={6}>
              <Box
                onClick={() => {
                  const nextDeviceId =
                    videoinput.deviceId === 'none'
                      ? availableDevices.videoinput[0]?.deviceId || 'none'
                      : 'none';
                  getHandleDeviceChange('video')(nextDeviceId);
                  dispatch(updateSelectedDevices({ videoinput: nextDeviceId }));
                }}
                sx={{
                  ...commonBoxStyles(theme, videoinput.deviceId !== 'none'),
                  minHeight: '130px',
                }}
              >
                {videoinput.deviceId === 'none' ? (
                  <VideoOff sx={{ fontSize: '2.75rem' }} />
                ) : (
                  <Video sx={{ fontSize: '2.75rem' }} />
                )}
                <Typography
                  variant="caption"
                  noWrap
                  sx={commonTypographyStyles(true)}
                >
                  {videoinput.deviceId === 'none'
                    ? 'Camera Off'
                    : availableDevices.videoinput.find(
                        (d) => d.deviceId === videoinput.deviceId
                      )?.label || 'Camera'}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            mt: 3,
          }}
        >
          <Button
            id="start-recording-button"
            variant="contained"
            color="secondary"
            onClick={handleStart}
            disabled={userMediaStatus === 'pending'}
            startIcon={<Disc sx={{ color: '#fff !important' }} />}
            sx={{
              backgroundColor: '#dc2625',
              padding: theme.spacing(1.5),
              color: '#fff',
              borderRadius: '999px',
              fontWeight: 500,
              fontSize: '1.2rem',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              transform: 'translateY(0)',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#b91c1c',
                transform: 'translateY(-4px)',
                boxShadow: '0 6px 20px rgba(220, 38, 38, 0.4)',
                color: '#fff',
              },
              '&:active': {
                transform: 'translateY(-2px)',
                color: '#fff',
              },
              '&:disabled': {
                transform: 'none',
                boxShadow: 'none',
                color: '#fff',
              },
            }}
          >
            Start Recording
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeviceSelection;
