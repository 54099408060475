import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip, useTheme, Box } from '@mui/material';
import {
  StopCircle as StopCircleIcon,
  Pause as PauseIcon,
  PlayCircleFilled as PlayIcon,
  Notes as NotesIcon,
} from '@mui/icons-material';
import {
  stopRecordingThunk,
  pauseRecordingThunk,
  resumeRecordingThunk,
} from '../../thunk';
import { RootState } from '../../store';

interface ControlButtonsProps {
  onToggleNotes: () => void;
  showNotes: boolean;
  isPopupMode?: boolean;
}

const ControlButtons: React.FC<ControlButtonsProps> = ({
  onToggleNotes,
  showNotes,
  isPopupMode = false,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { status } = useSelector((state: RootState) => state.recorder);
  const isRecording = status === 'recording';
  const isPaused = status === 'paused';
  const isControlDisabled = !['recording', 'paused'].includes(status);

  const iconColor =
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.light
      : theme.palette.secondary.contrastText;

  const disabledColor =
    theme.palette.mode === 'dark'
      ? theme.palette.action.disabled
      : theme.palette.secondary.dark;

  const stopRecordingHandler = () => {
    dispatch(stopRecordingThunk());
  };

  const togglePauseResumeHandler = () => {
    if (isPaused) {
      dispatch(resumeRecordingThunk());
    } else if (isRecording) {
      dispatch(pauseRecordingThunk());
    }
  };

  // Adjust button sizes based on mode
  const buttonSize = isPopupMode ? '2.5rem' : '3rem';
  const iconSize = isPopupMode ? '1.75rem' : '2rem';
  const stopIconSize = isPopupMode ? '2rem' : '2.5rem';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: isPopupMode ? '0.25rem' : '0.5rem',
      }}
    >
      {/* Only show notes button in fullscreen mode */}
      {!isPopupMode && (
        <Tooltip title={showNotes ? 'Hide Notes' : 'Show Notes'} arrow>
          <IconButton
            onClick={onToggleNotes}
            sx={{
              width: buttonSize,
              height: buttonSize,
              padding: '0.25rem',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                transform: 'translateY(-2px) scale(1.05)',
              },
              '&:active': {
                transform: 'translateY(0) scale(0.95)',
              },
            }}
          >
            <NotesIcon
              sx={{
                fontSize: iconSize,
                color: iconColor,
                opacity: showNotes ? 0.5 : 1,
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={isPaused ? 'Resume' : 'Pause'} arrow>
        <IconButton
          color="secondary"
          onClick={togglePauseResumeHandler}
          sx={{
            width: buttonSize,
            height: buttonSize,
            padding: '0.25rem',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
              transform: 'translateY(-2px) scale(1.05)',
            },
            '&:active': {
              transform: 'translateY(0) scale(0.95)',
            },
          }}
        >
          {isPaused ? (
            <PlayIcon
              sx={{
                fontSize: iconSize,
                color: isControlDisabled ? disabledColor : iconColor,
                transition: 'all 0.2s ease-in-out',
              }}
            />
          ) : (
            <PauseIcon
              sx={{
                fontSize: iconSize,
                color: isControlDisabled ? disabledColor : iconColor,
                transition: 'all 0.2s ease-in-out',
              }}
            />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Stop Recording" arrow>
        <IconButton
          color="secondary"
          onClick={stopRecordingHandler}
          disabled={isControlDisabled}
          sx={{
            width: buttonSize,
            height: buttonSize,
            padding: '0.25rem',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
              transform: 'translateY(-2px) scale(1.05)',
            },
            '&:active': {
              transform: 'translateY(0) scale(0.95)',
            },
            '&:hover .MuiSvgIcon-root': {
              color: '#FF0000',
            },
          }}
        >
          <StopCircleIcon
            id="stop-recording-button"
            sx={{
              fontSize: stopIconSize,
              color: isControlDisabled ? disabledColor : '#DC2626',
              transition: 'all 0.2s ease-in-out',
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ControlButtons;
