import { createTheme } from '@mui/material';
import type { Theme as MaterialUITheme } from '@mui/material';
import Grow from '@mui/material/Grow';
import {
  sharedStyleOverrides,
  sharedSvgIconStyles,
  sharedTypography,
  brandColors,
} from './shared';

// Define dark mode colors
const colors = {
  black: '#0F0F0F',
  white: '#FFFFFF',
  primaryMain: brandColors.primaryMain,
  primaryLight: brandColors.primaryLight,
  primaryDark: brandColors.primaryDark,
  secondaryMain: '#2A2B2C', // Darker gray
  secondaryLight: '#3A3B3C', // Dark gray
  secondaryDark: '#1A1B1C', // Very dark gray
  secondaryContrastText: '#E4E6EB', // Light gray text
  warningMain: '#F79804DE',
  infoMain: '#3696F3',
  errorMain: '#F44336',
  successMain: '#4CAF51',
  textPrimary: brandColors.textLight, // Light text for dark theme
  hoverColor: brandColors.interactiveDark,
  dividerColor: '#2A2B2C', // Darker gray for dividers
  backgroundDefault: '#121212', // Very dark gray but not pure black
  backgroundPaper: '#1A1A1A', // Dark gray with slight contrast against default
  skeletonColor: '#2A2B2C', // Darker gray for skeleton
  actionHover: '#2A2B2C', // Dark gray hover background
};

const palette = {
  mode: 'dark' as const,
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: {
    main: colors.primaryMain,
    light: colors.primaryLight,
    dark: colors.primaryDark,
    contrastText: colors.white,
  },
  background: {
    default: colors.backgroundDefault,
    paper: colors.backgroundPaper,
  },
  secondary: {
    main: colors.secondaryMain,
    light: colors.secondaryLight,
    dark: colors.secondaryDark,
    contrastText: colors.secondaryContrastText,
  },
  warning: {
    main: colors.warningMain,
    contrastText: colors.white,
  },
  info: {
    main: colors.infoMain,
    contrastText: colors.textPrimary,
  },
  error: {
    main: colors.errorMain,
    contrastText: colors.textPrimary,
  },
  success: {
    main: colors.successMain,
    contrastText: colors.textPrimary,
  },
  text: {
    primary: colors.textPrimary,
    secondary: colors.secondaryContrastText,
  },
  divider: colors.dividerColor,
  action: {
    hover: colors.actionHover,
  },
};

export const darkTheme = createTheme({
  palette,
  typography: sharedTypography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...sharedStyleOverrides,
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: colors.secondaryMain,
          borderRadius: '4px',
        },
        '*': {
          ...sharedStyleOverrides['*'],
          scrollbarColor: `${colors.secondaryMain} transparent`,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          ...sharedSvgIconStyles,
          color: colors.textPrimary,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: colors.backgroundPaper,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '9999px',
          '&.MuiButton-outlinedSecondary': {
            borderColor: colors.secondaryMain,
            color: colors.secondaryContrastText,
            '&:hover': {
              borderColor: colors.secondaryLight,
              backgroundColor: colors.secondaryLight,
              '& .MuiSvgIcon-root': {
                color: brandColors.interactiveDark,
              },
            },
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
          '&.MuiButton-text': {
            color: colors.textPrimary,
          },
        },
        containedPrimary: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.primaryDark,
            '& .MuiSvgIcon-root': {
              color: colors.white,
            },
          },
          '& .MuiSvgIcon-root': {
            color: colors.white,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
        containedSecondary: {
          backgroundColor: colors.secondaryMain,
          boxShadow: 'none',
          color: colors.secondaryContrastText,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.secondaryLight,
            '& .MuiSvgIcon-root': {
              color: brandColors.interactiveDark,
            },
          },
          '& .MuiSvgIcon-root': {
            color: colors.secondaryContrastText,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
        containedError: {
          boxShadow: 'none',
          backgroundColor: '#d32f2f',
          color: colors.white,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#b71c1c',
          },
          '& .MuiSvgIcon-root': {
            color: colors.white,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
        containedSuccess: {
          boxShadow: 'none',
          color: '#fff',
          fontWeight: 600,
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:hover .MuiSvgIcon-root': {
            color: brandColors.interactiveDark,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          '&.MuiListItemButton-gutters': {
            paddingLeft: 8 * 1.5,
            paddingRight: 0,
          },
          '& .MuiListItemIcon-root': {
            marginRight: '12px',
          },
          '&:hover .MuiSvgIcon-root': {
            color: brandColors.interactiveDark,
          },
          '&:hover': {
            backgroundColor: colors.secondaryLight,
          },
          '&:active': {
            transform: 'translateY(1px)',
            transition: 'transform 0.1s',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-outlinedSecondary': {
            bgcolor: colors.backgroundPaper,
            color: colors.textPrimary,
            '&:hover': {
              bgcolor: colors.secondaryLight,
              boxShadow: `0 1px 3px ${colors.secondaryDark}, 0 1px 2px ${colors.secondaryDark}`,
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: colors.backgroundPaper,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '1.5rem',
          height: '1.5rem',
          backgroundColor: colors.secondaryMain,
          color: colors.secondaryContrastText,
          '& .MuiSvgIcon-root': {
            color: colors.white,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.dividerColor,
          backgroundColor: colors.dividerColor,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        TransitionComponent: Grow,
        TransitionProps: {
          timeout: 300,
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.4)',
          backgroundColor: colors.backgroundPaper,
          border: `1px solid ${colors.secondaryMain}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: colors.backgroundPaper,
          border: `1px solid ${colors.secondaryDark}`,
          '&.MuiAlert-standardSuccess': {
            '& .MuiAlert-icon': {
              color: colors.successMain,
            },
          },
          '&.MuiAlert-standardError': {
            '& .MuiAlert-icon': {
              color: colors.errorMain,
            },
          },
          '&.MuiAlert-standardWarning': {
            '& .MuiAlert-icon': {
              color: colors.warningMain,
            },
          },
          '&.MuiAlert-standardInfo': {
            '& .MuiAlert-icon': {
              color: colors.infoMain,
            },
          },
        },
        icon: {
          display: 'flex',
          alignItems: 'center',
          marginRight: '12px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4)',
          backgroundColor: colors.backgroundPaper,
          border: `1px solid ${colors.secondaryMain}`,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4)',
          backgroundColor: colors.backgroundPaper,
          border: `1px solid ${colors.secondaryMain}`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.secondaryLight,
          border: `1px solid ${colors.secondaryMain}`,
          color: colors.textPrimary,
        },
        arrow: {
          color: colors.secondaryLight,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            border: `1px solid ${colors.secondaryMain}`,
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4)',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${colors.primaryMain}`,
          },
          '&:hover .MuiSvgIcon-root': {
            color: colors.hoverColor,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4)',
          backgroundColor: colors.backgroundPaper,
          border: `1px solid ${colors.secondaryMain}`,
        },
        root: {
          '& .MuiAutocomplete-inputRoot': {
            padding: '0',
          },
          '& .MuiAutocomplete-input': {
            padding: 0,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(70, 70, 70, 0.4)',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 8,
          backgroundColor: colors.secondaryDark,
        },
        bar: {
          borderRadius: 4,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.primaryLight,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.textPrimary,
          '&:hover': {
            color: brandColors.interactiveDark,
          },
        },
      },
    },
  },
});

export type Theme = MaterialUITheme;
export default darkTheme;
