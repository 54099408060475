import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { initStore } from './store';
import App from './App';
import { darkTheme } from '../theme/darkTheme';
import defaultTheme from '../theme/default';
import { setupState, setUIMode, setMountedFromPlugin } from './recorderSlice';
import getMiddleware from './middleware';
import { EventEmitter, EventListener, EventMap } from './EventEmitter';
import { getSettings } from '../services/authService';

const getRecorderComponent = (store: ReturnType<typeof initStore>) => {
  const Recorder = () => {
    // Get theme preference from localStorage to match main app
    const isDarkMode = React.useMemo(() => {
      const saved = localStorage.getItem('darkMode');
      return saved ? JSON.parse(saved) : false;
    }, []);

    const theme = React.useMemo(() => (isDarkMode ? darkTheme : defaultTheme), [
      isDarkMode,
    ]);

    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    );
  };
  return Recorder;
};

export default class ScreenAppRecorder {
  apiKey: string;

  parentElementSelector: string;

  teamId: string;

  folderId: string;

  settings: Parameters<typeof setupState>[0]['settings'];

  uiMode: 'fullscreen' | 'popup';

  isPlugin: boolean;

  private eventEmitter: EventEmitter;

  private root: ReturnType<typeof createRoot> | null = null;

  constructor({
    apiKey,
    parentElementSelector,
    teamId,
    folderId,
    settings,
    uiMode = 'fullscreen',
    isPlugin = false,
  }: {
    apiKey: string;
    parentElementSelector?: string;
    teamId: string;
    folderId: string;
    settings?: Parameters<typeof setupState>[0]['settings'];
    uiMode?: 'fullscreen' | 'popup';
    isPlugin?: boolean;
  }) {
    this.apiKey = apiKey;
    this.parentElementSelector = parentElementSelector || '#screenapp-recorder';
    this.teamId = teamId;
    this.folderId = folderId;
    this.settings = settings;
    this.uiMode = uiMode;
    this.isPlugin = isPlugin;
    this.eventEmitter = new EventEmitter();
    this.eventEmitter.on('uploaded', () => {
      this.destroy();
    });
    this.eventEmitter.on('canceled', () => {
      this.destroy();
    });
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }

  async initialize() {
    const store = initStore([getMiddleware(this.eventEmitter)]);
    if (!this.parentElementSelector) {
      document.body.insertAdjacentHTML(
        'beforeend',
        `<div id="${this.parentElementSelector.replace('#', '')}"></div>`
      );
    }
    const parentElement = document.querySelector(this.parentElementSelector);
    if (!parentElement) {
      throw new Error('Parent element not found');
    }

    // Retrieve settings from localStorage
    const localStorageSettings = localStorage.getItem('recorderSettings');
    const parsedSettings = localStorageSettings
      ? JSON.parse(localStorageSettings)
      : {};
    const { audioSettings, videoSettings } = await getSettings('recorder');
    this.settings = {
      audioSettings: {
        ...audioSettings,
        ...this.settings?.audioSettings,
        ...parsedSettings?.audioSettings, // Merge with localStorage settings
      },
      videoSettings: {
        ...videoSettings,
        ...this.settings?.videoSettings,
        ...parsedSettings?.videoSettings, // Merge with localStorage settings
      },
    };
    await store.dispatch(
      setupState({
        parentElementSelector: this.parentElementSelector,
        uploadState: { teamId: this.teamId, folderId: this.folderId },
        settings: this.settings,
      })
    );

    // Set the UI mode
    store.dispatch(setUIMode(this.uiMode));

    // Set that this recorder is mounted from the plugin only if it's actually from the plugin
    store.dispatch(setMountedFromPlugin(this.isPlugin));

    const RecorderComponent = getRecorderComponent(store);
    this.root = createRoot(parentElement);
    this.root.render(<RecorderComponent />);
    this.eventEmitter.emit('ready', {
      teamId: this.teamId,
      folderId: this.folderId,
    });
  }

  on<K extends keyof EventMap>(type: K, listener: EventListener<K>) {
    this.eventEmitter.on(type, listener);
  }

  off<K extends keyof EventMap>(type: K, listener: EventListener<K>) {
    this.eventEmitter.off(type, listener);
  }
}
