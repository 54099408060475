import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Recorder from './Recorder';
import { RootState } from './store';

const App: React.FC = () => {
  const { recorder } = useSelector((state: RootState) => state);
  const { status } = recorder;
  useEffect(() => {}, [status]);
  switch (status) {
    case 'initializing':
      return <div>Loading...</div>;
    case 'failed':
      return <div>Failed to load recorder</div>;
    default:
      return <Recorder />;
  }
};

export default App;
