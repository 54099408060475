import logger from '../../../lib/logger';
import { VideoStreamMerger } from '../../../lib/streamMerger';
import AudioStreamMerger from './AudioStreamMerger';

const DEFAULT_USER_VIDEO_CONSTRAINTS = {
  width: { max: 320 },
  height: { max: 320 },
  frameRate: { max: 30 },
};

const DEFAULT_DISPLAY_VIDEO_CONSTRAINTS = {
  cursor: 'always',
  logicalSurface: true,
  width: window.screen.width,
  height: window.screen.height,
  frameRate: { max: 30 },
};

const DEFAULT_DISPLAY_AUDIO_CONSTRAINTS = {
  echoCancellation: true,
  noiseSuppression: true,
  sampleRate: 48000,
};

export const streams = {
  userMediaStream: new MediaStream(),
  displayMediaStream: new MediaStream(),
  mergedStream: new MediaStream(),
};

let videoStreamMerger: VideoStreamMerger;
let audioStreamMerger: AudioStreamMerger;

type TrackInfo = Pick<MediaStreamTrack, 'id' | 'kind' | 'label'>;

export const replaceTracks = ({
  newStream,
  streamType,
}: {
  newStream: MediaStream;
  streamType: keyof typeof streams;
}): {
  streamId: string;
  trackInfo: TrackInfo[];
} => {
  const stream = streams[streamType];
  if (!newStream) throw new Error(`New ${streamType} is required`);
  const newTracks = newStream.getTracks();
  const existingTracks = stream?.getTracks() ?? [];
  existingTracks
    .filter((existingTrack) =>
      newTracks.find((newTrack) => newTrack.kind === existingTrack.kind)
    )
    .forEach((track) => {
      track.stop();
      stream?.removeTrack(track);
    });
  newTracks.forEach((track) => {
    stream?.addTrack(track);
  });
  return {
    streamId: stream.id,
    trackInfo: stream.getTracks().map(({ id, kind, label }) => ({
      id,
      kind,
      label,
    })),
  };
};

export const getUserMediaStream = async (
  userMediaConstraints?: Parameters<MediaDevices['getUserMedia']>[0]
): Promise<ReturnType<typeof replaceTracks>> => {
  if (!userMediaConstraints) {
    throw new Error('User media constraints are required');
  }
  let { video } = userMediaConstraints;
  const { audio } = userMediaConstraints;
  // If user sets a value to video or audio (even if it's false), we will stop the existing track
  const trackTypesToBeUpdated = Object.keys(userMediaConstraints).filter(
    (key) => userMediaConstraints[key as 'audio' | 'video'] !== undefined
  ) as ('video' | 'audio')[];
  const { userMediaStream } = streams;
  if (trackTypesToBeUpdated.length > 0) {
    trackTypesToBeUpdated.forEach((trackType) => {
      const track = userMediaStream
        .getTracks()
        .find((t) => t.kind === trackType);
      if (track) {
        logger.log(`Stopping track ${track.kind} ${track.id}`);
        track.stop();
        userMediaStream.removeTrack(track);
      }
    });
  }
  video = video
    ? {
        ...DEFAULT_USER_VIDEO_CONSTRAINTS,
        ...(typeof video === 'object' ? video : {}),
      }
    : false;
  const constraints = {
    video,
    audio,
  };

  if (audio || video) {
    const newUserMediaStream = await navigator.mediaDevices.getUserMedia(
      constraints
    );

    newUserMediaStream.getTracks().forEach((track) => {
      userMediaStream.addTrack(track);
    });
  }

  const trackInfo = userMediaStream.getTracks().map(({ id, kind, label }) => ({
    id,
    kind,
    label,
  }));
  return { streamId: userMediaStream.id, trackInfo };
};

export const getDisplayMediaStream = async (
  displayMediaConstraints: Parameters<MediaDevices['getDisplayMedia']>[0]
): Promise<ReturnType<typeof replaceTracks>> => {
  if (!displayMediaConstraints) {
    throw new Error('Display media constraints are required');
  }
  let { video, audio } = displayMediaConstraints;
  video = video
    ? {
        ...DEFAULT_DISPLAY_VIDEO_CONSTRAINTS,
        ...(typeof video === 'object' ? video : {}),
      }
    : false;
  audio = audio
    ? {
        ...DEFAULT_DISPLAY_AUDIO_CONSTRAINTS,
        ...(typeof audio === 'object' ? audio : {}),
      }
    : false;
  const newStream = await navigator.mediaDevices.getDisplayMedia({
    video,
    audio,
  });
  return replaceTracks({
    newStream,
    streamType: 'displayMediaStream',
  });
};

export const mergeStreams = async () => {
  const { userMediaStream, displayMediaStream, mergedStream } = streams;
  videoStreamMerger = new VideoStreamMerger();
  audioStreamMerger = new AudioStreamMerger();
  let audioTrack;
  let videoTrack;

  // If there is at least one audio track, initiate audio mixer
  if (
    displayMediaStream.getAudioTracks().length > 0 ||
    userMediaStream.getAudioTracks().length > 0
  ) {
    audioStreamMerger.addStream(displayMediaStream);
    audioStreamMerger.addStream(userMediaStream);
    [audioTrack] = audioStreamMerger.getOutputStream().getAudioTracks();
  }

  // If both user and display video tracks are available,
  // Use stream videoStreamMerger to merge them into a sigle video track
  if (
    displayMediaStream.getVideoTracks().length > 0 &&
    userMediaStream.getVideoTracks().length > 0
  ) {
    const {
      width: displayMediaWidth = window.screen.width,
      height: displayMediaHeight = window.screen.height,
    } = displayMediaStream.getVideoTracks()[0].getSettings() || {};
    videoStreamMerger.addStream(displayMediaStream, {
      draw: (ctx, frame, done) => {
        videoStreamMerger.setOutputSize(displayMediaWidth, displayMediaHeight);
        ctx?.save();
        ctx?.drawImage(
          frame,
          (videoStreamMerger.width - displayMediaWidth) / 2 + 1,
          (videoStreamMerger.height - displayMediaHeight) / 2,
          displayMediaWidth,
          displayMediaHeight
        );

        ctx?.restore();
        done();
      },
      // @ts-ignore
      audioEffect: null,
      height: videoStreamMerger.height,
      mute: !(displayMediaStream.getAudioTracks().length > 0),
      muted: !(displayMediaStream.getAudioTracks().length > 0),
      width: videoStreamMerger.width,
      x: 0,
      y: 0,
      index: 0,
    });
    const camDiameter = Math.min(displayMediaWidth, displayMediaHeight) / 4;
    videoStreamMerger.addStream(userMediaStream, {
      height: camDiameter,
      width: camDiameter,
      index: 1,
      draw: (ctx, frame, done) => {
        ctx?.save();
        // circular shape camera preview
        const radius = camDiameter / 2;
        const x = (videoStreamMerger.width - displayMediaWidth) / 2 + 10;
        const y =
          (videoStreamMerger.height - displayMediaHeight) / 2 +
          displayMediaHeight -
          camDiameter -
          10;
        videoStreamMerger.circularImage(ctx, x, y, radius);
        ctx?.clip();

        // Calculate the source dimensions to maintain aspect ratio
        const track = userMediaStream.getVideoTracks()[0];
        const settings = track.getSettings();
        const sourceWidth = settings.width || camDiameter;
        const sourceHeight = settings.height || camDiameter;
        const sourceAspectRatio = sourceWidth / sourceHeight;
        const targetAspectRatio = 1; // We want a square for the circular crop

        let drawWidth;
        let drawHeight;
        let offsetX;
        let offsetY;

        if (sourceAspectRatio > targetAspectRatio) {
          // Source is wider, crop the sides
          drawHeight = camDiameter;
          drawWidth = drawHeight * sourceAspectRatio;
          offsetX = (drawWidth - camDiameter) / 2;
          offsetY = 0;
        } else {
          // Source is taller, crop the top and bottom
          drawWidth = camDiameter;
          drawHeight = drawWidth / sourceAspectRatio;
          offsetX = 0;
          offsetY = (drawHeight - camDiameter) / 2;
        }

        ctx?.drawImage(frame, x - offsetX, y - offsetY, drawWidth, drawHeight);
        ctx?.restore();
        done();
      },
      // @ts-ignore
      audioEffect: null,
      mute: true,
      muted: true,
      x: 10,
      y: videoStreamMerger.height - camDiameter - 10,
    });

    videoStreamMerger.start(); // merge screen stream and camera stream
    [videoTrack] = videoStreamMerger.result?.getVideoTracks() || [];
  } else if (
    displayMediaStream.getVideoTracks().length > 0 &&
    !userMediaStream.getVideoTracks().length
  ) {
    [videoTrack] = displayMediaStream.getVideoTracks();
  } else if (
    !displayMediaStream.getVideoTracks().length &&
    userMediaStream.getVideoTracks().length > 0
  ) {
    [videoTrack] = userMediaStream.getVideoTracks();
  }

  if (videoTrack) {
    mergedStream.addTrack(videoTrack);
  }
  if (audioTrack) {
    mergedStream.addTrack(audioTrack);
  }
};

export const cleanup = () => {
  Object.keys(streams).forEach((key) => {
    streams[key as keyof typeof streams].getTracks().forEach((track) => {
      track.stop();
    });
    streams[key as keyof typeof streams] = new MediaStream();
  });
  // TODO: Find why videoStreamMerger being undefined
  videoStreamMerger?.stop();
  audioStreamMerger?.stop();
  // @ts-ignore
  videoStreamMerger = null;
  // @ts-ignore
  audioStreamMerger = null;
};
