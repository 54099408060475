import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Paper, IconButton, useTheme, Typography, Fade } from '@mui/material';
import { GripVertical, Maximize2 } from 'lucide-mui';
import { RootState } from '../store';
import RecorderControls from './RecorderControls';
import { setUIMode } from '../recorderSlice';
import DisplayPreview from './MediaPreview/Display';
import { streams } from './utils/streamUtils';

// Helper function to get box shadow based on component state
const getBoxShadow = (isDragging: boolean, isHovering: boolean) => {
  if (isDragging) {
    return '0 10px 30px rgba(0,0,0,0.15)';
  }
  if (isHovering) {
    return '0 8px 28px rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.05)';
  }
  return '0 4px 20px rgba(0,0,0,0.15)';
};

// Helper function to get transform style based on component state
const getTransform = (isDragging: boolean, isHovering: boolean) => {
  if (isDragging) {
    return 'scale(0.98)';
  }
  if (isHovering) {
    return 'scale(1.01)';
  }
  return 'scale(1)';
};

const PopupRecorder: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { status, upload } = useSelector((state: RootState) => state.recorder);
  const isBackdropInvisible = status === 'stopped';
  const isSaving = status === 'saving';
  const isRecording = status === 'recording' || status === 'paused';
  const videoTitle = upload.title || 'Screen Recording';
  
  // State for dragging
  const [position, setPosition] = useState({ x: 20, y: 20 }); // Start at top-left with padding
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const popupRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);

  // Check if we have video tracks
  const hasVideoTracks = streams.displayMediaStream?.getVideoTracks().length > 0;

  // Handle switching back to fullscreen mode
  const handleSwitchToFullscreen = () => {
    dispatch(setUIMode('fullscreen'));
  };

  // Handle drag start
  const handleMouseDown = (e: React.MouseEvent) => {
    if (popupRef.current) {
      setIsDragging(true);
      const rect = popupRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  // Handle drag
  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      const newX = e.clientX - dragOffset.x;
      const newY = e.clientY - dragOffset.y;
      
      // Ensure the popup stays within the viewport
      const maxX = window.innerWidth - (popupRef.current?.offsetWidth || 0);
      const maxY = window.innerHeight - (popupRef.current?.offsetHeight || 0);
      
      setPosition({
        x: Math.max(0, Math.min(newX, maxX)),
        y: Math.max(0, Math.min(newY, maxY)),
      });
    }
  };

  // Handle drag end
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Add event listeners for dragging outside the component
  React.useEffect(() => {
    const handleGlobalMouseMove = (e: MouseEvent) => {
      if (isDragging && popupRef.current) {
        const newX = e.clientX - dragOffset.x;
        const newY = e.clientY - dragOffset.y;
        
        // Ensure the popup stays within the viewport with some padding
        const maxX = window.innerWidth - popupRef.current.offsetWidth;
        const maxY = window.innerHeight - popupRef.current.offsetHeight;
        
        setPosition({
          x: Math.max(0, Math.min(newX, maxX)),
          y: Math.max(0, Math.min(newY, maxY)),
        });
      }
    };

    const handleGlobalMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleGlobalMouseMove);
      window.addEventListener('mouseup', handleGlobalMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleGlobalMouseMove);
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging, dragOffset]);

  // Ensure the popup is fully visible on the screen when it mounts
  useEffect(() => {
    if (popupRef.current) {
      const popupWidth = popupRef.current.offsetWidth;
      const popupHeight = popupRef.current.offsetHeight;
      
      // Calculate the maximum x and y positions to keep the popup fully visible
      const maxX = window.innerWidth - popupWidth - 20; // 20px padding from right edge
      const maxY = window.innerHeight - popupHeight - 20; // 20px padding from bottom edge
      
      // Set the initial position to be centered on the screen
      const centerX = Math.max(20, Math.min((window.innerWidth - popupWidth) / 2, maxX));
      const centerY = Math.max(20, Math.min((window.innerHeight - popupHeight) / 2, maxY));
      
      setPosition({ x: centerX, y: centerY });
    }
  }, []);

  // Handle window resize to keep popup within viewport
  useEffect(() => {
    const handleResize = () => {
      if (popupRef.current) {
        const popupWidth = popupRef.current.offsetWidth;
        const popupHeight = popupRef.current.offsetHeight;
        
        // Calculate the maximum x and y positions
        const maxX = window.innerWidth - popupWidth;
        const maxY = window.innerHeight - popupHeight;
        
        // Adjust position if needed to keep popup within viewport
        setPosition(prevPosition => ({
          x: Math.min(prevPosition.x, maxX),
          y: Math.min(prevPosition.y, maxY),
        }));
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Fade in timeout={400}>
      <Paper
        ref={popupRef}
        elevation={6}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        sx={{
          position: 'fixed',
          left: position.x,
          top: position.y,
          width: '400px',
          maxWidth: 'calc(100vw - 40px)', // Ensure it's not wider than the viewport minus padding
          borderRadius: '12px',
          overflow: 'hidden',
          opacity: isBackdropInvisible && !isSaving ? 0 : 1,
          transition: 'all 0.3s ease, transform 0.1s ease',
          transform: getTransform(isDragging, isHovering),
          zIndex: theme.zIndex.drawer + 1,
          cursor: isDragging ? 'grabbing' : 'auto',
          boxShadow: getBoxShadow(isDragging, isHovering),
          border: `1px solid ${theme.palette.mode === 'dark' 
            ? 'rgba(255,255,255,0.1)' 
            : 'rgba(0,0,0,0.05)'}`,
        }}
      >
        {/* Draggable header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            p: 1.2,
            cursor: isDragging ? 'grabbing' : 'grab',
            borderBottom: `1px solid ${theme.palette.mode === 'dark' 
              ? 'rgba(255,255,255,0.1)' 
              : 'rgba(0,0,0,0.05)'}`,
            transition: 'background-color 0.2s ease',
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            }
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <GripVertical fontSize="small" sx={{ opacity: 0.8 }} />
            <Typography
              variant="subtitle2"
              component="span"
              sx={{ 
                ml: 1.5, 
                fontWeight: 500,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '280px',
                fontSize: '0.9rem',
                letterSpacing: '0.01em'
              }}
            >
              {videoTitle}
            </Typography>
          </Box>
          <IconButton 
            size="small" 
            onClick={handleSwitchToFullscreen}
            sx={{ 
              color: theme.palette.primary.contrastText,
              opacity: 0.8,
              transition: 'all 0.2s ease',
              '&:hover': {
                opacity: 1,
                transform: 'scale(1.1)',
                bgcolor: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            <Maximize2 fontSize="small" />
          </IconButton>
        </Box>

        {/* Video preview (only shown when recording) */}
        {isRecording && hasVideoTracks && (
          <Box 
            sx={{ 
              width: '100%', 
              height: '225px', 
              overflow: 'hidden', 
              bgcolor: 'black',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                boxShadow: 'inset 0 0 20px rgba(0,0,0,0.3)',
                pointerEvents: 'none'
              }
            }}
          >
            <Box sx={{ 
              width: '100%', 
              height: '100%', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <DisplayPreview />
            </Box>
          </Box>
        )}

        {/* Recorder controls */}
        <Box sx={{ 
          p: 1.5,
          bgcolor: theme.palette.mode === 'dark' 
            ? 'rgba(30,30,30,0.9)' 
            : 'rgba(250,250,250,0.9)',
        }}>
          <RecorderControls 
            onToggleNotes={() => {}} 
            showNotes={false} 
            isPopupMode
          />
        </Box>
      </Paper>
    </Fade>
  );
};

export default PopupRecorder; 